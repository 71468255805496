import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import PageTitle from '../../components/PageTitle';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import SearchCard from '../../components/SearchCard';
import { AnimatePresence } from 'framer-motion';

interface MemberDetails {
  photo: string;
  fullName: string;
  permanentEnrollmentNumber: string;
  memberType: string;
  designation: string;
  phone: string;
  languagesKnown: string;
  memberStatus: string;
  currentState: string;
  currentDistrict: string;
  currentCity: string;
  currentPinCode: string;
  dateOfBirth: string;
  bloodGroup: string;
}

const Search = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [memberDetails, setMemberDetails] = useState<MemberDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMemberDetails(null);

    try {
      const membersRef = collection(db, 'userDetails');
      const querySnapshot = await getDocs(membersRef);
      
      const searchResults = querySnapshot.docs.filter(doc => {
        const data = doc.data();
        const fullName = data.fullName?.toLowerCase() || '';
        const enrollmentNumber = data.enrollmentNumber || '';
        const searchTerm = searchQuery.trim().toLowerCase();
        
        return fullName.includes(searchTerm) || enrollmentNumber.toLowerCase() === searchTerm;
      });

      if (searchResults.length === 0) {
        setError('No member found with the provided details.');
        return;
      }

      const data = searchResults[0].data();
      const memberData: MemberDetails = {
        photo: data.photo || '',
        fullName: data.fullName || '',
        permanentEnrollmentNumber: data.enrollmentNumber || '',
        memberType: data.memberType || '',
        designation: data.designation || '',
        phone: data.phone || '',
        languagesKnown: data.languagesKnown || '',
        memberStatus: data.memberStatus || '',
        currentState: data.currentState || '',
        currentDistrict: data.currentDistrict || '',
        currentCity: data.currentCityVillage || '',
        currentPinCode: data.currentPinCode || '',
        dateOfBirth: data.dateOfBirth || '',
        bloodGroup: data.bloodGroup || ''
      };
      
      setMemberDetails(memberData);
    } catch (error) {
      console.error('Error fetching member details:', error);
      setError('An error occurred while fetching member details.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-black">
      <PageTitle
        title="Search Member"
        subtitle="Search by Name or Enrollment Number"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Search Form */}
        <div className="max-w-3xl mx-auto mb-12">
          <form onSubmit={handleSearch} className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4">
            <div className="flex-1">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaSearch className="h-5 w-5 text-gray-100" />
                </div>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Enter name or enrollment number"
                  className="block w-full pl-10 pr-3 py-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-100 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <button
              type="submit"
              className="px-6 py-3 bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 text-white font-medium rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed shadow-lg shadow-blue-500/25"
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2" />
                  Searching...
                </div>
              ) : (
                'Search'
              )}
            </button>
          </form>
        </div>

        {/* Results Section */}
        <div className="max-w-4xl mx-auto">
          {error && (
            <div className="text-red-500 text-center py-4 bg-red-500/10 rounded-xl backdrop-blur-sm">
              {error}
            </div>
          )}

          <AnimatePresence mode="wait">
            {memberDetails && (
              <SearchCard member={memberDetails} />
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Search;
