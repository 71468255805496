import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaPhone, FaMapMarkerAlt, FaChevronDown, FaBuilding } from "react-icons/fa";
import { MdEmail } from 'react-icons/md';

const Contact: React.FC = () => {
  const [selectedOffice, setSelectedOffice] = useState("Select Regional Office");
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<{ type: 'success' | 'error' | null; message: string }>({
    type: null,
    message: ''
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setStatus({ type: null, message: '' });

    try {
      const response = await fetch('http://localhost:3001/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setStatus({
          type: 'success',
          message: 'Thank you for your message. We will get back to you soon!'
        });
        setFormData({ name: '', email: '', message: '' });
      } else {
        throw new Error(data.error || 'Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus({
        type: 'error',
        message: 'Sorry, there was an error sending your message. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  const contactInfo = [
    {
      title: "Phone Numbers",
      details: ["+91 7409832549", "+91 9411598376", "+91 7351771668", "0135-2988716"],
      icon: <FaPhone className="text-2xl" />
    },
    {
      title: "Email",
      details: ["info@ccroindia.in"],
      icon: <MdEmail className="text-2xl" />
    }
  ];

  const mainOffices = [
    {
      title: "Registered Office",
      address: [
        "CRIME CONTROL & RESEARCH ORGANIZATION",
        "B3/49A Yamuna Vihar",
        "Delhi - 110053"
      ]
    },
    {
      title: "Administration Office",
      address: [
        "CRIME CONTROL & RESEARCH ORGANIZATION",
        "Lower Kolhupani, Near Sunshine Play School",
        "Nanda Ki Chowki, Premnagar",
        "Dehradun, Uttarakhand"
      ]
    }
  ];

  const regionalOffices = [
    {
      title: "Select Regional Office",
      address: []
    },
    {
      title: "Punjab Office",
      address: [
        "16/A, Ground Floor, Ayero Homes",
        "Jandpur Road, Sec-123, Kharar",
        "S.A.S Nagar Mohali, Punjab - 140301"
      ]
    },
    {
      title: "Chandigarh Office",
      address: [
        "4807, Sector 38 West",
        "U.T. Chandigarh"
      ]
    },
    {
      title: "Andhra Pradesh Office",
      address: [
        "South Zone Office",
        "24/1/726, Nellore",
        "Andhra Pradesh, India - 0861"
      ]
    },
    {
      title: "Himachal Pradesh Office",
      address: [
        "W.No 01 Kot",
        "PO+Tehsil - Nadaun",
        "Hamirpur (HP) - 177033"
      ]
    },
    {
      title: "Uttar Pradesh Office",
      address: [
        "240, N-2 Road",
        "Lal Bangla, Kanpur Nagar",
        "Uttar Pradesh - 208007"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white relative">
      {/* Background Blur Effect */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900/20 via-gray-900/20 to-purple-900/20 backdrop-blur-[100px] pointer-events-none"></div>
      
      {/* Content Container */}
      <div className="relative z-10">
        {/* Header Section */}
        <div className="relative h-[400px] overflow-hidden -mt-[120px]">
          {/* Background with gradient overlay */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-b from-gray-900/50 to-gray-900"></div>
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_50%_-100%,#3b82f6,transparent)] opacity-20"></div>
          </div>

          {/* Header Content */}
          <div className="relative w-full h-full z-10 pt-[70px]">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-full">
              <div className="flex flex-col justify-center h-full text-center py-12 sm:py-16">
                {/* Content */}
                <div className="relative mx-auto max-w-3xl">
                  <div className="absolute -left-4 -top-4 w-72 h-72 bg-gradient-to-br from-blue-600/20 to-transparent opacity-50 rounded-full blur-2xl animate-pulse hidden sm:block" />
                  <div className="relative space-y-4 sm:space-y-6">
                    <div className="inline-flex items-center space-x-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-blue-500/10 border border-blue-500/20 backdrop-blur-sm">
                      <span className="relative flex h-2 w-2">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
                      </span>
                      <span className="text-xs sm:text-sm text-blue-200 font-medium">Get in Touch</span>
                    </div>
                    
                    <h1 className="text-3xl sm:text-4xl font-bold tracking-tight text-white bg-clip-text animate-fade-in-up relative">
                      Contact Us
                      <span className="block mt-2 text-2xl sm:text-4xl text-blue-100/90">We're Here to Help</span>
                      <div className="absolute -right-4 bottom-0 w-24 h-24 bg-gradient-to-tr from-blue-500 to-transparent opacity-20 rounded-full blur-xl animate-pulse delay-200 hidden sm:block" />
                    </h1>
                    
                    <div className="relative">
                      <p className="mt-4 sm:mt-6 text-base sm:text-xl leading-7 sm:leading-8 text-blue-100/80 animate-fade-in-up delay-200 max-w-2xl mx-auto px-4 sm:px-0">
                        Reach out to us for inquiries, support, or collaboration.
                      </p>
                      <div className="absolute -left-4 -bottom-4 w-32 h-32 bg-gradient-to-br from-indigo-500 to-transparent opacity-20 rounded-full blur-xl animate-pulse delay-300 hidden sm:block" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Floating shapes - Only show on larger screens */}
          <div className="absolute inset-0 overflow-hidden hidden sm:block">
            <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-blue-500/10 rounded-full blur-3xl animate-blob" />
            <div className="absolute top-1/3 right-1/4 w-72 h-72 bg-indigo-500/10 rounded-full blur-3xl animate-blob animation-delay-2000" />
            <div className="absolute bottom-1/4 left-1/3 w-80 h-80 bg-cyan-500/10 rounded-full blur-3xl animate-blob animation-delay-4000" />
          </div>
        </div>

        {/* Main Content */}
        <div className="relative z-20 container mx-auto px-4 py-12">
          {/* Section 1: Contact Form & Information */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="mb-12 sm:mb-20"
          >
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-12">Get in Touch</h2>
            <div className="grid md:grid-cols-2 gap-6 sm:gap-8">
              {/* Contact Form */}
              <motion.form
                onSubmit={handleSubmit}
                className="space-y-4 sm:space-y-6 bg-gray-800/50 backdrop-blur-sm rounded-lg p-6 sm:p-8 border border-white/10"
              >
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-200 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    className="mt-1 block w-full rounded-md bg-gray-700/50 border border-gray-600 text-white px-3 sm:px-4 py-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-200 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="mt-1 block w-full rounded-md bg-gray-700/50 border border-gray-600 text-white px-3 sm:px-4 py-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-200 mb-1">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                    rows={4}
                    className="mt-1 block w-full rounded-md bg-gray-700/50 border border-gray-600 text-white px-3 sm:px-4 py-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={loading}
                    className={`w-full flex justify-center py-3 sm:py-3.5 px-4 sm:px-5 border border-transparent rounded-md shadow-sm text-sm sm:text-base font-medium text-white ${
                      loading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  >
                    {loading ? 'Sending...' : 'Send Message'}
                  </button>
                </div>
                {status.type && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className={`p-4 rounded-md ${
                      status.type === 'success' ? 'bg-green-500/20 text-green-200' : 'bg-red-500/20 text-red-200'
                    }`}
                  >
                    {status.message}
                  </motion.div>
                )}
              </motion.form>

              {/* Contact Information Cards */}
              <div className="flex flex-col gap-4 sm:gap-8">
                {contactInfo.map((info, index) => (
                  <motion.div
                    key={info.title}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 sm:p-8 shadow-lg flex-1"
                    whileHover={{ scale: 1.02 }}
                  >
                    <div className="flex items-center gap-3 sm:gap-4 mb-3 sm:mb-4">
                      <div className="p-2 sm:p-3 bg-blue-600 rounded-lg">
                        {info.icon}
                      </div>
                      <h3 className="text-lg sm:text-xl font-semibold">{info.title}</h3>
                    </div>
                    <div className="space-y-1 sm:space-y-2">
                      {info.details.map((detail, i) => (
                        <p key={i} className="text-gray-300 text-base sm:text-[22px] font-medium">{detail}</p>
                      ))}
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>

          {/* Section 2: All Offices */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-12">Our Offices</h2>
            
            {/* Main Offices */}
            <div className="grid md:grid-cols-2 gap-6 sm:gap-8 mb-8 sm:mb-12">
              {mainOffices.map((office, index) => (
                <motion.div
                  key={office.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-5 sm:p-6 shadow-lg"
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-center gap-3 sm:gap-4 mb-3 sm:mb-4">
                    <div className="p-2 sm:p-3 bg-blue-600 rounded-lg">
                      <FaBuilding className="text-xl sm:text-2xl" />
                    </div>
                    <h3 className="text-lg sm:text-xl font-semibold">{office.title}</h3>
                  </div>
                  <div className="space-y-1 sm:space-y-2">
                    {office.address.map((line, i) => (
                      <p key={i} className="text-gray-300 text-sm sm:text-base">{line}</p>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>

            {/* Regional Offices */}
            <motion.div
              className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 sm:p-8 shadow-lg"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.2 }}
            >
              <div className="flex items-center gap-3 sm:gap-4 mb-4 sm:mb-6">
                <div className="p-2 sm:p-3 bg-blue-600 rounded-lg">
                  <FaMapMarkerAlt className="text-xl sm:text-2xl" />
                </div>
                <h3 className="text-xl sm:text-2xl font-semibold">Regional Offices</h3>
              </div>
              
              <div className="space-y-4 sm:space-y-6">
                <div className="relative">
                  <select
                    value={selectedOffice}
                    onChange={(e) => setSelectedOffice(e.target.value)}
                    className="w-full bg-gray-800/50 backdrop-blur-sm rounded-lg px-3 sm:px-4 py-2.5 sm:py-3 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-300 border border-white/10 appearance-none text-sm sm:text-base"
                  >
                    {regionalOffices.map((office) => (
                      <option 
                        key={office.title} 
                        value={office.title}
                        className="bg-gray-800"
                      >
                        {office.title}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 sm:px-3 pointer-events-none">
                    <FaChevronDown className="text-gray-300" />
                  </div>
                </div>

                {selectedOffice !== "Select Regional Office" && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="space-y-1 sm:space-y-2"
                  >
                    {regionalOffices
                      .find((office) => office.title === selectedOffice)
                      ?.address.map((line, i) => (
                        <p key={i} className="text-gray-300 text-sm sm:text-base">{line}</p>
                      ))}
                  </motion.div>
                )}
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Contact;