import { initializeApp, getApps } from 'firebase/app';
import { getAuth, browserSessionPersistence, setPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCt_PES8yUhtfMPMyF-ywpiXZL-3QpplJc",
  authDomain: "ccro-acc09.firebaseapp.com",
  projectId: "ccro-acc09",
  storageBucket: "ccro-acc09.firebasestorage.app",
  messagingSenderId: "431900059555",
  appId: "1:431900059555:web:b30069514e09f1db101c16",
  measurementId: "G-TGQKE183QT"
};

// Initialize Firebase only if it hasn't been initialized
let app;
if (getApps().length === 0) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Function to get logo URL
export const getLogoUrl = async () => {
  try {
    const storageRef = ref(storage, 'assets/logo.png');
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error('Error getting logo URL:', error);
    // Fallback to a default hosted image URL
    return 'https://firebasestorage.googleapis.com/v0/b/ccro-acc09.appspot.com/o/assets%2Flogo.png?alt=media';
  }
};

// Set auth persistence
setPersistence(auth, browserSessionPersistence).catch(console.error);

export default app;
