import { ToastOptions } from 'react-hot-toast';

export const toastConfig: ToastOptions = {
  style: {
    background: '#0f172a', // slate-900
    color: '#f1f5f9', // slate-100
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid rgba(148, 163, 184, 0.1)', // slate-400/10
    backdropFilter: 'blur(8px)',
  },
  duration: 3000,
  iconTheme: {
    primary: '#059669', // emerald-600
    secondary: '#ecfdf5', // emerald-50
  },
};
