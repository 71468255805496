import React, { useState, useRef } from 'react';
import { Timestamp, collection, addDoc, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, auth, storage } from '../../../config/firebase';
import { toast } from 'react-hot-toast';
import { FaPaperPlane, FaUpload, FaDownload, FaFile, FaSpinner } from 'react-icons/fa';

interface Message {
  id: string;
  content: string;
  userId: string;
  userName: string;
  userDisplayName: string;
  userPhotoURL: string | null;
  createdAt: Timestamp;
  isSystem: boolean;
  isAdmin: boolean;
  fileURL?: string;
  fileName?: string;
  fileType?: string;
}

interface ChatBoxProps {
  ticketId: string;
  messages: Message[];
  loading: boolean;
}

const formatTimeOnly = (timestamp: Timestamp | null) => {
  if (!timestamp) return '';
  try {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return '';
  } catch (error) {
    return '';
  }
};

export const ChatBox: React.FC<ChatBoxProps> = ({ ticketId, messages, loading }) => {
  const [newMessage, setNewMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const currentUser = auth.currentUser;

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() || !ticketId || !currentUser) return;

    setSendingMessage(true);
    try {
      // Check if current user is admin
      const isAdminUser = currentUser.email?.endsWith('@admin.com') || false;
      console.log('Current user:', {
        email: currentUser.email,
        isAdmin: isAdminUser
      });
      
      const messageData = {
        content: newMessage.trim(),
        userId: currentUser.uid,
        userName: isAdminUser ? 'Admin' : currentUser.email,
        userDisplayName: isAdminUser ? 'Admin' : (currentUser.displayName || currentUser.email?.split('@')[0] || 'Anonymous'),
        userPhotoURL: currentUser.photoURL,
        createdAt: serverTimestamp(),
        isSystem: false,
        isAdmin: isAdminUser
      };

      console.log('Saving message:', messageData);

      const messagesRef = collection(db, 'support_tickets', ticketId, 'messages');
      await addDoc(messagesRef, messageData);

      // Update ticket
      await updateDoc(doc(db, 'support_tickets', ticketId), {
        lastMessage: newMessage.trim(),
        lastMessageTime: serverTimestamp(),
        lastUpdated: serverTimestamp(),
        unreadAdmin: !isAdminUser,
        unreadUser: isAdminUser
      });

      setNewMessage('');
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    } finally {
      setSendingMessage(false);
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !currentUser || !ticketId) return;

    setUploading(true);
    try {
      // Create a reference to the file in Firebase Storage
      const fileRef = ref(storage, `support_tickets/${ticketId}/${Date.now()}_${file.name}`);
      
      // Upload the file
      await uploadBytes(fileRef, file);
      
      // Get the download URL
      const downloadURL = await getDownloadURL(fileRef);

      // Add a new message with the file info
      const messageData = {
        content: `Uploaded file: ${file.name}`,
        userId: currentUser.uid,
        userName: currentUser.email,
        userDisplayName: currentUser.displayName || currentUser.email?.split('@')[0] || 'Anonymous',
        userPhotoURL: currentUser.photoURL,
        createdAt: serverTimestamp(),
        isSystem: false,
        isAdmin: false,
        fileURL: downloadURL,
        fileName: file.name,
        fileType: file.type
      };

      const messagesRef = collection(db, 'support_tickets', ticketId, 'messages');
      await addDoc(messagesRef, messageData);

      // Update ticket
      await updateDoc(doc(db, 'support_tickets', ticketId), {
        lastMessage: `Uploaded file: ${file.name}`,
        lastMessageTime: serverTimestamp(),
        lastUpdated: serverTimestamp(),
        unreadAdmin: true,
        unreadUser: false
      });

      toast.success('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleFileDownload = async (message: Message) => {
    if (!message.fileURL || !message.fileName) return;

    try {
      // Get the download URL
      const response = await fetch(message.fileURL);
      const blob = await response.blob();

      // Create a download link
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = message.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Delete the file from storage
      const fileRef = ref(storage, message.fileURL);
      await deleteObject(fileRef);

      // Update the message to remove file info
      const messageRef = doc(db, 'support_tickets', ticketId, 'messages', message.id);
      await updateDoc(messageRef, {
        fileURL: null,
        fileName: null,
        fileType: null,
        content: `File was downloaded: ${message.fileName}`
      });

      toast.success('File downloaded and removed from storage');
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download file');
    }
  };

  return (
    <div className="flex flex-col h-full bg-[#0f172a]">
      <div className="flex-grow overflow-y-auto p-4">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-2 border-blue-500 border-t-transparent"></div>
          </div>
        ) : (
          <div className="space-y-4">
            {messages.map((message) => {
              const isMyMessage = message.userId === currentUser?.uid;
              
              return (
                <div
                  key={message.id}
                  className={`flex ${isMyMessage ? 'justify-end' : 'justify-start'} mb-4`}
                >
                  <div
                    className={`max-w-[70%] rounded-lg px-4 py-2 ${
                      isMyMessage
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-200 text-gray-800'
                    }`}
                  >
                    {!isMyMessage && (
                      <div className="text-xs text-gray-500 mb-1">
                        {message.isAdmin ? 'Admin' : message.userDisplayName}
                      </div>
                    )}
                    <div className="text-sm break-words">
                      {message.content}
                      {message.fileURL && (
                        <div className="mt-2 flex items-center gap-2">
                          <FaFile className="text-gray-400" />
                          <span className="text-sm text-gray-300">{message.fileName}</span>
                          <button
                            onClick={() => handleFileDownload(message)}
                            className="ml-2 text-blue-400 hover:text-blue-300 transition-colors"
                          >
                            <FaDownload />
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="text-xs opacity-70 text-right mt-1">
                      {formatTimeOnly(message.createdAt)}
                    </div>
                  </div>
                </div>
              );
            })}
            <div ref={messagesEndRef} />
          </div>
        )}
      </div>

      <div className="p-4 bg-[#1e293b]">
        <form onSubmit={handleSendMessage} className="flex gap-2">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message"
            className="flex-grow p-2 bg-[#0f172a] rounded-lg text-white placeholder-gray-400 focus:outline-none"
            disabled={sendingMessage}
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            className="hidden"
            disabled={uploading}
          />
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            disabled={uploading}
            className={`p-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 disabled:opacity-50`}
          >
            {uploading ? <FaSpinner className="animate-spin h-5 w-5" /> : <FaUpload className="h-5 w-5" />}
          </button>
          <button
            type="submit"
            disabled={sendingMessage || !newMessage.trim()}
            className="p-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
          >
            {sendingMessage ? <FaSpinner className="animate-spin h-5 w-5" /> : <FaPaperPlane className="h-5 w-5" />}
          </button>
        </form>
      </div>
    </div>
  );
};
