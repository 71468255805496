import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaEnvelope, FaLock, FaPhone, FaEye, FaEyeSlash } from 'react-icons/fa';
import { auth } from '../../config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';

// Helper function to validate and format dates
const formatDate = (date: string | Date): string => {
  if (!date) return '';
  if (date instanceof Date) {
    return date.toISOString().split('T')[0];
  }
  return date;
};

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState<{ type: 'error' | 'success', message: string } | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Validate form data
      if (!formData.fullName || !formData.email || !formData.password || !formData.confirmPassword || !formData.phone) {
        setValidationMessage({ type: 'error', message: 'Please fill in all fields' });
        setIsLoading(false);
        return;
      }

      if (formData.password !== formData.confirmPassword) {
        setValidationMessage({ type: 'error', message: 'Passwords do not match' });
        setIsLoading(false);
        return;
      }

      // Validate phone number format
      const phoneRegex = /^[6-9]\d{9}$/;
      if (!phoneRegex.test(formData.phone)) {
        setValidationMessage({ type: 'error', message: 'Please enter a valid 10-digit mobile number' });
        setIsLoading(false);
        return;
      }

      // Check if email already exists in userDetails collection
      const usersRef = collection(db, 'userDetails');
      const q = query(usersRef, where('email', '==', formData.email.toLowerCase()));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setValidationMessage({ type: 'error', message: 'An account with this email already exists' });
        setIsLoading(false);
        return;
      }

      // Create user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      // Save additional user details to Firestore
      await setDoc(doc(db, 'userDetails', userCredential.user.uid), {
        fullName: formData.fullName,
        email: formData.email.toLowerCase(),
        phone: '+91' + formData.phone,
        authUid: userCredential.user.uid,
        authEmail: formData.email.toLowerCase(),
        authDisplayName: formData.fullName,
        aadhaarNumber: '',
        memberType: '',
        memberStatus: 'Pending',
        designation: '',  
        officeState: '',
        officeDistrict: '',
        officeCityVillage: '',
        enrollmentNumber: '',
        range: '',
        rank: '',
        validFrom: formatDate(new Date()),
        currentAddressLine1: '',
        currentAddressLine2: '',
        currentState: '',
        currentDistrict: '',
        currentCityVillage: '',
        currentPinCode: '',
        currentStdCode: '',
        permanentAddressLine1: '',
        permanentAddressLine2: '',
        permanentState: '',
        permanentDistrict: '',
        permanentCityVillage: '',
        permanentPinCode: '',
        permanentStdCode: '',
        sameAsCurrent: false,
        witness1Name: '',
        witness1Phone: '',
        witness1Aadhaar: '',
        witness2Name: '',
        witness2Phone: '',
        witness2Aadhaar: '',
        history: [],
        photo: '',
        aadhaarFront: '',
        aadhaarBack: '',
        vehicleRegistration: '',
        promissoryNote: '',
        gender: '',
        dateOfBirth: '',
        bloodGroup: '',
        fatherOrHusbandName: '',
        education: '',
        languagesKnown: '',
        identificationMark: '',
        profession: '',
        postHeld: '',
        vehicleMake: '',
        vehicleModel: '',
        profileUpdated: false,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        userRole: 'user',
        verificationStatus: 'pending',
        emailVerified: false,
        adminVerified: false
      });

      // Sign out the user after registration
      await auth.signOut();

      setValidationMessage({ type: 'success', message: 'Registration successful! Please login to continue.' });
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error: any) {
      console.error('Registration error:', error);
      // Handle specific Firebase errors
      switch (error.code) {
        case 'auth/email-already-in-use':
          setValidationMessage({ type: 'error', message: 'Email is already registered' });
          break;
        case 'auth/invalid-email':
          setValidationMessage({ type: 'error', message: 'Invalid email address' });
          break;
        case 'auth/weak-password':
          setValidationMessage({ type: 'error', message: 'Password should be at least 6 characters' });
          break;
        case 'auth/network-request-failed':
          setValidationMessage({ type: 'error', message: 'Network error. Please check your internet connection.' });
          break;
        default:
          setValidationMessage({ type: 'error', message: 'Failed to register. Please try again.' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800 -mt-12 p-2">
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-slate-900/50 via-slate-900/50 to-slate-900/90" />
      {/* Animated background elements */}
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute w-screen h-screen">
          <div className="absolute top-0 left-0 w-[200%] h-[200%] origin-top-left bg-gradient-to-br from-blue-500/5 to-purple-500/5 animate-rotate-slow" />
          <div className="absolute bottom-0 right-0 w-[200%] h-[200%] origin-bottom-right bg-gradient-to-tl from-indigo-500/5 to-pink-500/5 animate-rotate-slow-reverse" />
        </div>
      </div>

      {/* Register Form */}
      <div className="max-w-md w-full relative z-10 mx-auto px-4">
        <div className="floating-card glass-effect rounded-2xl p-8 shadow-2xl backdrop-blur-xl bg-slate-900/40">
          {/* Logo Section */}
          <div className="text-center space-y-4 mb-6">
            <div className="relative inline-block">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-purple-500/30 rounded-full blur-2xl opacity-75 animate-pulse" />
              <Link to="/">
                <img
                  src="/images/logo.png"
                  alt="Logo"
                  className="relative mx-auto h-24 w-auto animate-float"
                />
              </Link>
            </div>
            
            <div className="space-y-2">
              <h2 className="text-4xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                Create Account
              </h2>
              <p className="text-dark-text-secondary text-lg">
                Join us in making a safer tomorrow
              </p>
            </div>
          </div>

          {/* Form Section */}
          <form 
            onSubmit={handleSubmit} 
            className="space-y-6" 
            autoComplete="off"
            data-lpignore="true"
          >
            {/* Full Name Input */}
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <FaUser className="h-5 w-5 text-gray-500" style={{ filter: 'none' }} />
              </div>
              <input
                type="text"
                name="fullName"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                data-lpignore="true"
                value={formData.fullName}
                onChange={handleChange}
                className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-transparent bg-white/10 backdrop-blur-sm text-gray-400 placeholder-gray-400 [&:-webkit-autofill]:bg-white/5 [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-text-fill-color:rgb(156,163,175)]"
                placeholder="Full Name"
              />
            </div>

            {/* Mobile Number Input */}
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <FaPhone className="h-5 w-5 text-gray-500" style={{ filter: 'none' }} />
              </div>
              <div className="absolute inset-y-0 left-10 flex items-center">
                <span className="text-gray-500">+91</span>
              </div>
              <input
                type="tel"
                name="phone"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                data-lpignore="true"
                value={formData.phone}
                onChange={handleChange}
                className="block w-full pl-20 pr-3 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-transparent bg-white/10 backdrop-blur-sm text-gray-400 placeholder-gray-400 [&:-webkit-autofill]:bg-white/5 [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-text-fill-color:rgb(156,163,175)]"
                placeholder="Mobile Number"
                maxLength={10}
              />
            </div>

            {/* Email Input */}
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <FaEnvelope className="h-5 w-5 text-gray-500" style={{ filter: 'none' }} />
              </div>
              <input
                type="email"
                name="email"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                data-lpignore="true"
                value={formData.email}
                onChange={handleChange}
                className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-transparent bg-white/10 backdrop-blur-sm text-gray-400 placeholder-gray-400 [&:-webkit-autofill]:bg-white/5 [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-text-fill-color:rgb(156,163,175)]"
                placeholder="Email address"
              />
            </div>

            {/* Password Input */}
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <FaLock className="h-5 w-5 text-gray-500" style={{ filter: 'none' }} />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                autoComplete="new-password"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                data-lpignore="true"
                data-form-type="other"
                value={formData.password}
                onChange={handleChange}
                className="block w-full pl-10 pr-12 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-transparent bg-white/10 backdrop-blur-sm text-gray-400 placeholder-gray-400 [&:-webkit-autofill]:bg-white/5 [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-text-fill-color:rgb(156,163,175)]"
                placeholder="Password"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400"
              >
                {showPassword ? <FaEyeSlash className="h-5 w-5 filter-none" /> : <FaEye className="h-5 w-5 filter-none" />}
              </button>
            </div>

            {/* Confirm Password Input */}
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <FaLock className="h-5 w-5 text-gray-500" style={{ filter: 'none' }} />
              </div>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                autoComplete="new-password"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                data-lpignore="true"
                data-form-type="other"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="block w-full pl-10 pr-12 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-transparent bg-white/10 backdrop-blur-sm text-gray-400 placeholder-gray-400 [&:-webkit-autofill]:bg-white/5 [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-text-fill-color:rgb(156,163,175)]"
                placeholder="Confirm Password"
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400"
              >
                {showConfirmPassword ? <FaEyeSlash className="h-5 w-5 filter-none" /> : <FaEye className="h-5 w-5 filter-none" />}
              </button>
            </div>

            {/* Submit Button */}
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl blur opacity-20 group-hover:opacity-40 transition duration-1000 group-hover:duration-200" />
              <button
                type="submit"
                className="relative btn-primary group w-full flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Create Account'}
              </button>
            </div>

            {/* Login Link */}
            <div className="text-center mt-4">
              <span className="text-gray-400">Already have an account? </span>
              <Link to="/login" className="text-blue-400 hover:text-blue-300 font-medium">
                Sign in
              </Link>
            </div>
          </form>
        </div>

        {/* Validation Message */}
        {validationMessage && (
          <div
            className={`fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-3 rounded-lg shadow-lg z-50 transition-all duration-300 backdrop-blur-sm ${
              validationMessage.type === 'error'
                ? 'bg-red-950 text-red-100'
                : 'bg-[#064e3b] text-emerald-100'
            }`}
          >
            {validationMessage.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
