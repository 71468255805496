import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { FaCalendar, FaUsers, FaMapMarkerAlt, FaClock, FaDollarSign } from 'react-icons/fa';
import { format } from 'date-fns';
import PageTitle from '../../components/PageTitle';

interface Event {
  id: string;
  title: string;
  description: string;
  content: string | any;
  featuredImage?: {
    url: string;
    alt: string;
  };
  startDate: Timestamp;
  endDate: Timestamp;
  location: string;
  price?: number;
  capacity: number;
  registeredCount: number;
  status: 'draft' | 'published' | 'scheduled';
}

const EventDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEvent = async () => {
      if (!id) return;

      try {
        setLoading(true);
        const eventDoc = await getDoc(doc(db, 'events', id));
        
        if (!eventDoc.exists()) {
          setError('Event not found');
          return;
        }

        setEvent({ id: eventDoc.id, ...eventDoc.data() } as Event);
      } catch (err) {
        console.error('Error fetching event:', err);
        setError('Failed to load event details');
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id]);

  const formatDate = (timestamp: Timestamp) => {
    try {
      return format(timestamp.toDate(), 'MMMM d, yyyy h:mm a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date not available';
    }
  };

  const formatPrice = (price: number | undefined) => {
    if (price === undefined || price === 0) return 'Free';
    return `₹${price.toLocaleString('en-IN')}`;
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error || !event) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-center text-white">
          <p className="text-xl mb-4">{error || 'Event not found'}</p>
          <button
            onClick={() => navigate('/events')}
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            Back to Events
          </button>
        </div>
      </div>
    );
  }

  const isEventFree = event.price === undefined || event.price === 0;
  const now = new Date();
  const startDate = event.startDate.toDate();
  const endDate = event.endDate.toDate();
  const isEventEnded = endDate < now;
  const isEventStarted = startDate <= now;
  const isFullyBooked = event.registeredCount >= event.capacity;

  return (
    <div className="min-h-screen bg-gray-900">
      <PageTitle
        title={event.title}
        subtitle="Event Details"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-gray-800 rounded-lg overflow-hidden shadow-xl">
          {/* Event Image */}
          {event.featuredImage ? (
            <img
              src={event.featuredImage.url}
              alt={event.featuredImage.alt || event.title}
              className="w-full h-96 object-cover"
            />
          ) : (
            <div className="w-full h-96 bg-gray-700 flex items-center justify-center">
              <FaCalendar className="w-24 h-24 text-gray-500" />
            </div>
          )}

          {/* Event Content */}
          <div className="p-8">
            {/* Status Badge */}
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-3xl font-bold text-white">{event.title}</h1>
              <span
                className={`px-4 py-2 rounded-full text-sm font-medium ${
                  isEventEnded
                    ? 'bg-gray-600 text-gray-200'
                    : isFullyBooked
                    ? 'bg-red-600 text-white'
                    : isEventStarted
                    ? 'bg-yellow-600 text-white'
                    : 'bg-green-600 text-white'
                }`}
              >
                {isEventEnded
                  ? 'Event Ended'
                  : isFullyBooked
                  ? 'Fully Booked'
                  : isEventStarted
                  ? 'In Progress'
                  : 'Registration Open'}
              </span>
            </div>

            {/* Event Details */}
            <div className="space-y-6">
              <div className="text-gray-300 text-lg">{event.description}</div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div className="flex items-center text-gray-300">
                    <FaClock className="w-5 h-5 mr-3" />
                    <div>
                      <div>Start: {formatDate(event.startDate)}</div>
                      <div>End: {formatDate(event.endDate)}</div>
                    </div>
                  </div>

                  <div className="flex items-center text-gray-300">
                    <FaMapMarkerAlt className="w-5 h-5 mr-3" />
                    <span>{event.location}</span>
                  </div>
                </div>

                <div className="space-y-4">
                  <div className="flex items-center text-gray-300">
                    <FaUsers className="w-5 h-5 mr-3" />
                    <span>
                      {event.registeredCount} / {event.capacity} spots filled
                    </span>
                  </div>

                  <div className="flex items-center text-gray-300">
                    <FaDollarSign className="w-5 h-5 mr-3" />
                    <span className={isEventFree ? 'text-green-400' : ''}>
                      {formatPrice(event.price)}
                    </span>
                  </div>
                </div>
              </div>

              {/* Event Content */}
              <div className="mt-8 prose prose-invert max-w-none">
                {typeof event.content === 'string' ? (
                  <div dangerouslySetInnerHTML={{ __html: event.content }} />
                ) : (
                  <div>
                    {event.content?.blocks?.map((block: any, index: number) => (
                      <div key={block.key || index} className="mb-4">
                        {block.text}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Action Buttons */}
              <div className="mt-8">
                <button
                  onClick={() => navigate('/events')}
                  className="px-6 py-3 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors"
                >
                  Back to Events
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
