/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { QRCodeSVG } from 'qrcode.react';
import { auth, db, storage, ref, getDownloadURL } from '../../../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { FaDownload, FaSync } from 'react-icons/fa';

interface MemberDetails {
  fullName: string;
  permanentEnrollmentNumber: string;
  memberType: string;
  designation: string;
  phone: string;
  languagesKnown: string;
  memberStatus: string;
  currentState: string;
  currentDistrict: string;
  currentCity: string;
  currentPinCode: string;
  dateOfBirth: string;
  bloodGroup: string;
}

const QRCodePage = () => {
  const [memberDetails, setMemberDetails] = useState<MemberDetails | null>(null);
  const [qrSize, setQrSize] = useState(300);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMemberDetails = async (userId: string) => {
      try {
        // First try userDetails collection
        const userDetailsRef = doc(db, 'userDetails', userId);
        const userDetailsDoc = await getDoc(userDetailsRef);
        
        let data;
        if (userDetailsDoc.exists()) {
          data = userDetailsDoc.data();
          console.log('Found user in userDetails:', data);
        } else {
          // Try users collection
          const usersRef = doc(db, 'users', userId);
          const usersDoc = await getDoc(usersRef);
          
          if (usersDoc.exists()) {
            data = usersDoc.data();
            console.log('Found user in users:', data);
          } else {
            console.log('No user document found in any collection');
            toast.error('User details not found');
            return;
          }
        }

        setMemberDetails({
          fullName: data.fullName || '',
          permanentEnrollmentNumber: data.enrollmentNumber || '',
          memberType: data.memberType || '',
          designation: data.designation || '',
          phone: data.phone || '',
          languagesKnown: data.languagesKnown || '',
          memberStatus: data.memberStatus || '',
          currentState: data.currentState || '',
          currentDistrict: data.currentDistrict || '',
          currentCity: data.currentCityVillage || '',
          currentPinCode: data.currentPinCode || '',
          dateOfBirth: data.dateOfBirth || '',
          bloodGroup: data.bloodGroup || ''
        });
      } catch (error) {
        console.error('Error fetching member details:', error);
        toast.error('Failed to fetch member details');
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('Current user ID:', user.uid);
        fetchMemberDetails(user.uid);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const capitalizeWords = (str: string) => {
    return str.split(' ').map(word => word.toUpperCase()).join(' ');
  };

  const getQRValue = () => {
    if (!memberDetails) return '';
    return `NAME: ${capitalizeWords(memberDetails.fullName)}\n` +
           `ENROLLMENT: ${memberDetails.permanentEnrollmentNumber}\n` +
           `TYPE: ${capitalizeWords(memberDetails.memberType)}\n` +
           `DESIGNATION: ${capitalizeWords(memberDetails.designation)}\n` +
           `STATUS: ${capitalizeWords(memberDetails.memberStatus)}\n` +
           `LANGUAGES: ${capitalizeWords(memberDetails.languagesKnown)}\n` +
           `ADDRESS: ${capitalizeWords(memberDetails.currentCity)}, \n` +
           `${capitalizeWords(memberDetails.currentDistrict)}\n` +
           `${capitalizeWords(memberDetails.currentState)}\n` +
           `PIN CODE: ${memberDetails.currentPinCode}\n` +
           `BIRTH DATE: ${memberDetails.dateOfBirth}\n` +
           `BLOOD GROUP: ${memberDetails.bloodGroup}`;
  };

  const downloadQRCode = () => {
    const svg = document.querySelector('svg');
    if (svg) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const svgData = new XMLSerializer().serializeToString(svg);
      const img = new Image();
      
      img.onload = () => {
        canvas.width = qrSize;
        canvas.height = qrSize;
        ctx?.drawImage(img, 0, 0);
        
        const pngUrl = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `qrcode_${memberDetails?.permanentEnrollmentNumber || 'member'}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toast.success('QR Code downloaded successfully!');
      };
      
      img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!memberDetails) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-400 mb-4">No member details found</p>
          <button
            onClick={() => window.location.reload()}
            className="inline-flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90"
          >
            <FaSync className="mr-2" />
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800 rounded-xl p-8"
        >
          <h1 className="text-2xl font-bold text-white mb-6">Member QR Code</h1>
          
          <div className="flex flex-col md:flex-row gap-8">
            {/* QR Code Section */}
            <div className="flex flex-col items-center space-y-6">
              <div className="bg-white p-8 rounded-xl shadow-lg">
                <QRCodeSVG
                  value={getQRValue()}
                  size={350}  
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="L"   
                  includeMargin={true}
                />
              </div>

              <button
                onClick={downloadQRCode}
                className="inline-flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90"
              >
                <FaDownload className="mr-2" />
                Download QR Code
              </button>
            </div>

            {/* Member Details Section */}
            <div className="flex-1 bg-gray-700/50 rounded-lg p-6">
              <h2 className="text-lg font-semibold text-white mb-4">QR Code Preview:</h2>
              <pre className="text-sm text-gray-300 bg-gray-800 p-4 rounded-lg whitespace-pre-wrap">
                {getQRValue()}
              </pre>
            </div>
          </div>

          {/* Instructions */}
          <div className="mt-8 bg-blue-500/10 text-blue-300 rounded-lg p-6">
            <h3 className="font-semibold mb-2">How to use:</h3>
            <ol className="list-decimal list-inside space-y-1">
              <li>Open any QR code scanner app</li>
              <li>Point camera at the QR code</li>
              <li>Hold steady until it scans</li>
              <li>View your member details</li>
            </ol>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default QRCodePage;
