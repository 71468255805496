import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { Toaster } from 'react-hot-toast';
import { toastConfig } from './config/toast';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import District from './pages/District';
import Download from './pages/Download/Download';
import Events from './pages/Events';
import EventDetails from './pages/Events/EventDetails';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import National from './pages/National';
import Photo from './pages/Photo';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import Search from './pages/Search';
import State from './pages/State';
import VerifyEmail from './pages/VerifyEmail';
import Video from './pages/Video';
import Dashboard from './pages/Dashboard';
import Articles from './pages/Dashboard/Articles';
import NewArticle from './pages/Dashboard/NewArticle';
import EditArticle from './pages/Dashboard/EditArticle';
import QRCode from './pages/Dashboard/QRCode';
import DashboardDownload from './pages/Dashboard/Downloads';
import Notifications from './pages/Dashboard/Notifications';
import Support from './pages/Dashboard/Support';
import UserDetails from './pages/Dashboard/UserDetails';
import DashboardLayout from './pages/Dashboard/Layout/DashboardLayout';
import ProtectedRoute from './components/ProtectedRoute';
import MembershipProtectedRoute from './components/MembershipProtectedRoute';

const App = () => {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-300">
            <Toaster
              position="bottom-center"
              reverseOrder={false}
              gutter={8}
              toastOptions={{
                ...toastConfig,
                success: {
                  style: {
                    ...toastConfig.style,
                    background: '#064e3b', // dark green
                  },
                },
                error: {
                  style: {
                    ...toastConfig.style,
                    background: '#7f1d1d', // dark red
                  },
                  duration: 4000,
                },
              }}
            />

            <Routes>
              {/* Auth Routes */}
              <Route path="/login" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <Login />
                  </main>
                </>
              } />
              <Route path="/register" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <Register />
                  </main>
                </>
              } />
              <Route path="/forgot-password" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <ForgotPassword />
                  </main>
                </>
              } />
              <Route path="/verify-email" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <VerifyEmail />
                  </main>
                </>
              } />
              <Route path="/auth/action" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <ResetPassword />
                  </main>
                </>
              } />

              {/* Dashboard Routes */}
              <Route path="/dashboard" element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
                <Route index element={<Dashboard />} />
                <Route path="articles" element={
                  <MembershipProtectedRoute>
                    <Articles />
                  </MembershipProtectedRoute>
                } />
                <Route path="new-article" element={
                  <MembershipProtectedRoute>
                    <NewArticle />
                  </MembershipProtectedRoute>
                } />
                <Route path="edit-article/:id" element={
                  <MembershipProtectedRoute>
                    <EditArticle />
                  </MembershipProtectedRoute>
                } />
                <Route path="qr-code" element={
                  <MembershipProtectedRoute>
                    <QRCode />
                  </MembershipProtectedRoute>
                } />
                <Route path="download" element={
                  <MembershipProtectedRoute>
                    <DashboardDownload />
                  </MembershipProtectedRoute>
                } />
                <Route path="notifications" element={
                  <MembershipProtectedRoute>
                    <Notifications />
                  </MembershipProtectedRoute>
                } />
                <Route path="support" element={<Support />} />
                <Route path="user-details" element={<UserDetails />} />
              </Route>

              {/* Public Routes with Header and Footer */}
              <Route path="/" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <Home />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/about" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <About />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/contact" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <Contact />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/district" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <District />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/download" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <Download />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/events" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <Events />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/events/:id" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <EventDetails />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/national" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <National />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/photo" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <Photo />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/search" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <Search />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/state" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <State />
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/video" element={
                <>
                  <Header />
                  <main className="pt-24">
                    <Video />
                  </main>
                  <Footer />
                </>
              } />
            </Routes>
          </div>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
