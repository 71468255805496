import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, useLocation, Outlet } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaNewspaper, 
  FaPlusCircle,  
  FaQrcode, 
  FaDownload, 
  FaBell, 
  FaHeadset, 
  FaSignOutAlt,
  FaBars,
  FaTimes,
  FaHome,
  FaUserCog,
  FaChevronRight
} from 'react-icons/fa';
import { auth, db } from '../../../config/firebase';
import { signOut } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import { getDoc, doc } from 'firebase/firestore';

const menuItems = [
  { name: 'Dashboard', href: '/dashboard', icon: FaHome },
  { name: 'Articles', href: '/dashboard/articles', icon: FaNewspaper },
  { name: 'New Article', href: '/dashboard/new-article', icon: FaPlusCircle },
  { name: 'QR Code', href: '/dashboard/qr-code', icon: FaQrcode },
  { name: 'Download', href: '/dashboard/download', icon: FaDownload },
  { name: 'Notifications', href: '/dashboard/notifications', icon: FaBell },
  { name: 'Support', href: '/dashboard/support', icon: FaHeadset }
];

const DashboardLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [currentMenuItems, setCurrentMenuItems] = useState(menuItems);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const TIMEOUT_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds

  // Handle session timeout
  const handleSessionTimeout = useCallback(() => {
    signOut(auth).then(() => {
      toast.error('Session expired. Please login again.');
      navigate('/login');
    });
  }, [navigate]);

  // Reset timer on user activity
  const resetTimer = useCallback(() => {
    if (window.sessionTimeoutId) {
      clearTimeout(window.sessionTimeoutId);
    }
    window.sessionTimeoutId = setTimeout(handleSessionTimeout, TIMEOUT_DURATION);
  }, [handleSessionTimeout]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 1024;
      setIsMobile(mobile);
      if (!mobile) setIsSidebarOpen(true);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Initialize timer
    resetTimer();

    // Setup event listeners for user activity
    const events = ['mousedown', 'keydown', 'scroll', 'touchstart'];
    events.forEach(event => {
      document.addEventListener(event, resetTimer);
    });

    // Cleanup
    return () => {
      if (window.sessionTimeoutId) {
        clearTimeout(window.sessionTimeoutId);
      }
      events.forEach(event => {
        document.removeEventListener(event, resetTimer);
      });
    };
  }, [resetTimer]);

  useEffect(() => {
    const checkUserStatus = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.log('No authenticated user found');
        navigate('/login');
        return;
      }

      const userDoc = await getDoc(doc(db, 'userDetails', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User data:', {
          profileUpdated: userData.profileUpdated,
          currentPath: location.pathname
        });
        
        // Show User Details in menu only if profile is not updated
        const updatedMenuItems = [...menuItems];
        if (!userData.profileUpdated) {
          updatedMenuItems.push({ name: 'User Details', href: '/dashboard/user-details', icon: FaUserCog });
        }
        setCurrentMenuItems(updatedMenuItems);

        // If profile not updated and not already on user-details page, redirect
        if (!userData.profileUpdated && location.pathname !== '/dashboard/user-details') {
          console.log('Redirecting to user-details: profile not updated');
          navigate('/dashboard/user-details');
          return;
        }

        // If profile is updated and on user-details page, redirect to dashboard
        if (userData.profileUpdated && location.pathname === '/dashboard/user-details') {
          console.log('Redirecting to dashboard: profile is updated');
          navigate('/dashboard');
          return;
        }

        // Make user-details the default page if profile not updated
        if (!userData.profileUpdated && location.pathname === '/dashboard') {
          console.log('Redirecting to user-details: on dashboard with unupdated profile');
          navigate('/dashboard/user-details');
        }
      } else {
        console.log('User document not found in Firestore');
      }
    };

    checkUserStatus();
  }, [navigate, location.pathname]);

  useEffect(() => {
    // Disable text selection
    const style = document.createElement('style');
    style.textContent = `
      body {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    `;
    document.head.appendChild(style);

    // Disable right click
    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };

    // Disable keyboard shortcuts
    const handleKeyDown = (e: KeyboardEvent) => {
      // Prevent Ctrl+S, Ctrl+U, Ctrl+P, F12
      if (
        (e.ctrlKey && (e.key === 's' || e.key === 'u' || e.key === 'p')) ||
        e.key === 'F12'
      ) {
        e.preventDefault();
      }
    };

    // Disable drag and drop
    const handleDragStart = (e: DragEvent) => {
      e.preventDefault();
    };

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('dragstart', handleDragStart);

    // Clean up
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('dragstart', handleDragStart);
      document.head.removeChild(style);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success('Logged out successfully');
      navigate('/login');
    } catch (error) {
      toast.error('Failed to logout');
    }
  };

  const sidebarVariants = {
    open: {
      x: 0,
      width: isCollapsed ? "80px" : "280px",
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 25,
        mass: 1,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    closed: {
      x: "-100%",
      width: isCollapsed ? "80px" : "280px",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 35,
        mass: 0.8
      }
    }
  };

  const menuItemVariants = {
    open: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 250,
        damping: 20
      }
    },
    closed: {
      x: -20,
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 25
      }
    }
  };

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      {/* Mobile Overlay */}
      {isMobile && isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <AnimatePresence>
        {(isSidebarOpen || !isMobile) && (
          <motion.aside
            initial={isMobile ? "closed" : "open"}
            animate="open"
            exit="closed"
            variants={sidebarVariants}
            className={`fixed lg:relative h-full bg-white/5 dark:bg-slate-800/20 backdrop-blur-md z-30 transition-all duration-300 border-r border-white/10 ${
              isCollapsed ? 'w-20' : 'w-72'
            } relative overflow-hidden`}
          >
            {/* Background Animation Elements */}
            <div className="absolute inset-0 pointer-events-none">
              <div className="absolute -left-1/2 top-0 w-full h-[500px] bg-gradient-to-r from-blue-500/10 via-purple-500/5 to-transparent rotate-12 animate-wave" />
              <div className="absolute -left-1/2 top-1/3 w-full h-[500px] bg-gradient-to-r from-emerald-500/10 via-sky-500/5 to-transparent -rotate-12 animate-wave-slow" />
              <div className="absolute -left-1/2 bottom-0 w-full h-[500px] bg-gradient-to-r from-indigo-500/10 via-violet-500/5 to-transparent rotate-12 animate-wave-slower" />
            </div>

            {/* Logo Section */}
            <div className="flex items-center justify-between p-4 border-b border-white/10 relative">
              {!isCollapsed && (
                <Link to="/" className="flex items-center">
                  <img src="/images/logo.png" alt="Logo" className="h-12 w-auto" />
                </Link>
              )}
              {isCollapsed && (
                <img src="/images/logo.png" alt="Logo" className="h-10 w-auto mx-auto" />
              )}
              {!isMobile && (
                <button
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                >
                  <FaChevronRight className={`w-5 h-5 text-gray-500 transform transition-transform ${
                    isCollapsed ? 'rotate-180' : ''
                  }`} />
                </button>
              )}
              {isMobile && (
                <button
                  onClick={() => setIsSidebarOpen(false)}
                  className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <FaTimes className="w-5 h-5 text-gray-500" />
                </button>
              )}
            </div>

            {/* Navigation */}
            <nav className="p-4">
              <ul className="space-y-2">
                {currentMenuItems.map((item) => {
                  const isActive = location.pathname === item.href;
                  return (
                    <motion.li
                      key={item.name}
                      variants={menuItemVariants}
                      initial="closed"
                      animate="open"
                      exit="closed"
                      whileHover={{
                        scale: 1.02,
                        y: -2,
                        transition: { type: "spring", stiffness: 400 }
                      }}
                      className="relative"
                    >
                      <Link
                        to={item.href}
                        className={`flex items-center p-3 rounded-xl transition-all duration-200 group hover:shadow-lg ${
                          isActive
                            ? 'bg-blue-500 text-white shadow-lg shadow-blue-500/30'
                            : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:shadow-gray-200/50 dark:hover:shadow-gray-800/30'
                        }`}
                      >
                        <motion.div
                          className="flex items-center w-full"
                          whileHover={{ scale: 1.05 }}
                          transition={{ type: "spring", stiffness: 400, damping: 17 }}
                        >
                          <div className={`flex items-center ${!isCollapsed && 'w-full'}`}>
                            <item.icon className={`w-5 h-5 ${!isCollapsed && 'mr-3'} ${
                              isActive ? 'text-white' : 'text-gray-500 group-hover:text-gray-700 dark:text-gray-400'
                            }`} />
                            {!isCollapsed && (
                              <span className="whitespace-nowrap">{item.name}</span>
                            )}
                          </div>
                          {!isCollapsed && (
                            <motion.div
                              className="absolute right-2 opacity-0 group-hover:opacity-100"
                              initial={{ x: -10 }}
                              whileHover={{ x: 0 }}
                              transition={{ duration: 0.2 }}
                            >
                              <FaChevronRight className={`w-3 h-3 ${isActive ? 'text-white' : 'text-gray-400'}`} />
                            </motion.div>
                          )}
                        </motion.div>
                      </Link>
                    </motion.li>
                  );
                })}
                <li>
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full p-3 text-gray-600 dark:text-gray-300 rounded-xl hover:bg-red-50 dark:hover:bg-red-900/20 hover:text-red-500 transition-colors duration-200 group"
                  >
                    <FaSignOutAlt className="w-5 h-5 text-gray-500 group-hover:text-red-500 dark:text-gray-400" />
                    {!isCollapsed && <span className="ml-3">Logout</span>}
                  </button>
                </li>
              </ul>
            </nav>
          </motion.aside>
        )}
      </AnimatePresence>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Navigation (Mobile) */}
        <header className="bg-white dark:bg-gray-800 shadow-sm lg:hidden">
          <div className="flex items-center justify-between p-4">
            <div className="flex items-center space-x-3">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <FaBars className="w-6 h-6 text-gray-500 dark:text-gray-400" />
              </button>
              <img src="/images/logo.png" alt="Logo" className="h-8 w-auto" />
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={handleLogout}
                className="p-2 text-gray-500 hover:text-red-500 transition-colors"
              >
                <FaSignOutAlt className="w-5 h-5" />
              </button>
            </div>
          </div>
        </header>

        {/* Page Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-50 dark:bg-gray-900">
          <div className="container mx-auto px-4 py-8">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

// Add TypeScript declaration for the global window object
declare global {
  interface Window {
    sessionTimeoutId: ReturnType<typeof setTimeout>;
  }
}

export default DashboardLayout;
