import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { collection, query, getDocs, orderBy, Timestamp, doc, getDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { toast } from 'react-hot-toast';
import { FaBell, FaClock, FaUsers, FaCircle, FaTimes, FaExpandAlt } from 'react-icons/fa';
import { useAuth } from '../../../hooks/useAuth';

interface Notification {
  id: string;
  title: string;
  message: string;
  priority: 'low' | 'medium' | 'high';
  status: 'sent' | 'pending' | 'failed';
  targetAudience: string;
  targetEmail?: string;
  targetUserId?: string;
  scheduledDate: string;
  createdAt: Timestamp;
  authorId?: string;
}

interface AuthorDetails {
  firstName?: string;
  lastName?: string;
  email?: string;
}

// Utility functions
const getPriorityColor = (priority: string) => {
  switch (priority.toLowerCase()) {
    case 'high':
      return 'text-red-400';
    case 'medium':
      return 'text-yellow-400';
    case 'low':
      return 'text-green-400';
    default:
      return 'text-slate-400';
  }
};

const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'sent':
      return 'bg-emerald-500/20 text-emerald-400';
    case 'pending':
      return 'bg-yellow-500/20 text-yellow-400';
    case 'failed':
      return 'bg-red-500/20 text-red-400';
    default:
      return 'bg-slate-500/20 text-slate-400';
  }
};

const NotificationModal: React.FC<{
  notification: Notification | null;
  onClose: () => void;
}> = ({ notification, onClose }) => {
  if (!notification) return null;

  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.95,
      y: 20
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      y: 20,
      transition: {
        duration: 0.2
      }
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4"
        variants={overlayVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={onClose}
      >
        <motion.div
          className="bg-slate-800 rounded-2xl w-full max-w-2xl overflow-hidden"
          variants={modalVariants}
          onClick={e => e.stopPropagation()}
        >
          {/* Modal Header */}
          <div className="p-6 bg-slate-900/50 flex items-start justify-between">
            <div>
              <h2 className="text-2xl font-bold text-white">
                {notification.title}
              </h2>
              <div className="flex items-center gap-3 mt-2">
                <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(notification.status)}`}>
                  {notification.status}
                </span>
                <span className={`flex items-center gap-1 text-sm ${getPriorityColor(notification.priority)}`}>
                  <FaCircle className="h-2 w-2" />
                  <span className="capitalize">{notification.priority} Priority</span>
                </span>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors"
            >
              <FaTimes className="h-5 w-5 text-slate-400 hover:text-white" />
            </button>
          </div>

          {/* Modal Content */}
          <div className="p-6 space-y-6">
            <div className="prose prose-invert max-w-none">
              <p className="text-slate-300 text-lg leading-relaxed">
                {notification.message}
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-slate-900/30 rounded-xl p-4">
                <div className="flex items-center gap-2 text-slate-400 mb-1">
                  <FaClock className="h-4 w-4" />
                  <span className="text-sm font-medium">Scheduled For</span>
                </div>
                <p className="text-white">
                  {new Date(notification.scheduledDate).toLocaleString()}
                </p>
              </div>

              <div className="bg-slate-900/30 rounded-xl p-4">
                <div className="flex items-center gap-2 text-slate-400 mb-1">
                  <FaUsers className="h-4 w-4" />
                  <span className="text-sm font-medium">Target Audience</span>
                </div>
                <p className="text-white capitalize">
                  {notification.targetAudience}
                </p>
              </div>
            </div>

            <div className="border-t border-slate-700/50 pt-4">
              <div className="flex items-center gap-2 text-slate-400">
                <FaClock className="h-4 w-4" />
                <span className="text-sm">
                  Created on {notification.createdAt.toDate().toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

const Notifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
  const { user } = useAuth();

  const fetchAuthorDetails = async (userId: string | undefined): Promise<AuthorDetails | null> => {
    if (!userId) return null;
    
    try {
      console.log('Fetching author details for userId:', userId);
      const userDetailsRef = doc(db, 'userDetails', userId);
      const userDetailsDoc = await getDoc(userDetailsRef);
      console.log('Found in userDetails:', userDetailsDoc.data());
      
      if (userDetailsDoc.exists()) {
        return userDetailsDoc.data() as AuthorDetails;
      }
      return null;
    } catch (error) {
      console.error('Error fetching author details:', error);
      return null;
    }
  };

  const fetchNotifications = useCallback(async () => {
    if (!user) return;
    
    try {
      const notificationsRef = collection(db, 'notifications');
      const q = query(notificationsRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      
      const notificationsData = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Notification[];

      // Filter notifications to show public messages and private messages for the current user
      const filteredNotifications = notificationsData.filter(notification => 
        notification.targetAudience === 'all' || 
        (notification.targetAudience === 'verified' && user.emailVerified) ||
        (notification.targetEmail && notification.targetEmail === user.email) ||
        (notification.targetAudience === 'private' && notification.targetUserId === user.uid)
      );

      // Fetch author details for each notification
      const notificationsWithAuthors = await Promise.all(
        filteredNotifications.map(async notification => {
          if (notification.authorId) {
            const authorDetails = await fetchAuthorDetails(notification.authorId);
            return {
              ...notification,
              authorDetails
            };
          }
          return notification;
        })
      );

      setNotifications(notificationsWithAuthors);
    } catch (error) {
      toast.error('Failed to fetch notifications');
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-white flex items-center gap-3">
          <FaBell className="h-6 w-6 text-blue-500" />
          Notifications
        </h1>
      </div>

      <div className="grid gap-4">
        <AnimatePresence>
          {notifications.map((notification, index) => (
            <motion.div
              key={notification.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ delay: index * 0.1 }}
              className="bg-slate-800 rounded-xl p-6 hover:bg-slate-800/80 transition-colors"
            >
              <div className="flex items-start justify-between gap-4">
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-3 mb-2">
                    <h3 className="text-lg font-semibold text-white truncate">
                      {notification.title}
                    </h3>
                    <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(notification.status)}`}>
                      {notification.status}
                    </span>
                    <span className={`flex items-center gap-1 text-sm ${getPriorityColor(notification.priority)}`}>
                      <FaCircle className="h-2 w-2" />
                      <span className="capitalize">{notification.priority}</span>
                    </span>
                  </div>
                  <p className="text-slate-300 line-clamp-2 mb-3">
                    {notification.message}
                  </p>
                  <div className="flex items-center gap-4 text-sm text-slate-400">
                    <div className="flex items-center gap-1">
                      <FaClock className="h-4 w-4" />
                      <span>
                        {new Date(notification.scheduledDate).toLocaleString()}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <FaUsers className="h-4 w-4" />
                      <span className="capitalize">
                        {notification.targetAudience}
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => setSelectedNotification(notification)}
                  className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors flex-shrink-0"
                >
                  <FaExpandAlt className="h-5 w-5 text-slate-400 hover:text-white" />
                </button>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {selectedNotification && (
        <NotificationModal
          notification={selectedNotification}
          onClose={() => setSelectedNotification(null)}
        />
      )}
    </div>
  );
};

export default Notifications;
