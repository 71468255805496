import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { collection, query, orderBy, Timestamp, addDoc, serverTimestamp, where, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { db, auth } from '../../../config/firebase';
import { toast } from 'react-hot-toast';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaTicketAlt, FaClock, FaUser, FaTimes, FaExpandAlt, FaCircle, FaPlus, FaPaperPlane } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ChatBox } from './ChatBox';

interface SupportTicket {
  id: string;
  title: string;
  description: string;
  category: 'technical' | 'billing' | 'general' | 'other';
  priority: 'low' | 'medium' | 'high';
  status: 'open' | 'in-progress' | 'resolved' | 'closed';
  userId: string;
  userName: string;
  userDisplayName: string;
  userPhotoURL: string | null;
  isPrivate: boolean;
  createdAt: Timestamp | null;
  lastUpdated: Timestamp | null;
  unreadAdmin: boolean;
  unreadUser: boolean;
  lastMessage: string;
  lastMessageTime: Timestamp | null;
}

interface Message {
  id: string;
  content: string;
  userId: string;
  userName: string;
  userDisplayName: string;
  userPhotoURL: string | null;
  createdAt: Timestamp;
  isSystem: boolean;
  isAdmin: boolean;
}

// Utility functions
const getPriorityColor = (priority: string) => {
  switch (priority.toLowerCase()) {
    case 'high':
      return 'text-red-400';
    case 'medium':
      return 'text-yellow-400';
    case 'low':
      return 'text-green-400';
    default:
      return 'text-slate-400';
  }
};

const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'resolved':
      return 'bg-emerald-500/20 text-emerald-400';
    case 'in-progress':
      return 'bg-yellow-500/20 text-yellow-400';
    case 'open':
      return 'bg-blue-500/20 text-blue-400';
    case 'closed':
      return 'bg-slate-500/20 text-slate-400';
    default:
      return 'bg-slate-500/20 text-slate-400';
  }
};

const getCategoryIcon = (category: string) => {
  switch (category.toLowerCase()) {
    case 'technical':
      return '🔧';
    case 'billing':
      return '💳';
    case 'general':
      return '📝';
    default:
      return '❓';
  }
};

const formatTimestamp = (timestamp: Timestamp | null) => {
  if (!timestamp) return 'N/A';
  try {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleString();
    }
    return 'Invalid Date';
  } catch (error) {
    return 'N/A';
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const formatTimeOnly = (timestamp: Timestamp | null) => {
  if (!timestamp) return '';
  try {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleTimeString();
    }
    return '';
  } catch (error) {
    return '';
  }
};

const TicketModal: React.FC<{
  ticket: SupportTicket | null;
  onClose: () => void;
}> = ({ ticket, onClose }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (!ticket || !currentUser) return;

    // Mark user messages as read when opening the ticket
    const updateReadStatus = async () => {
      try {
        await updateDoc(doc(db, 'support_tickets', ticket.id), {
          unreadUser: false
        });
      } catch (error) {
        console.error('Error updating read status:', error);
      }
    };
    updateReadStatus();

    // Subscribe to messages
    const messagesRef = collection(db, 'support_tickets', ticket.id, 'messages');
    const q = query(messagesRef, orderBy('createdAt', 'asc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messageData = snapshot.docs.map(doc => {
        const data = doc.data();
        console.log('Loading message:', data);
        return {
          id: doc.id,
          ...data,
          isAdmin: data.userName?.includes('@admin.com') || false
        };
      }) as Message[];
      console.log('Loaded messages:', messageData);
      setMessages(messageData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [ticket, currentUser]);

  if (!ticket) return null;

  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.95,
      y: 20
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      y: 20,
      transition: {
        duration: 0.2
      }
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4"
        variants={overlayVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={onClose}
      >
        <motion.div
          className="bg-slate-800 rounded-2xl w-full max-w-2xl overflow-hidden flex flex-col h-[80vh]"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={e => e.stopPropagation()}
        >
          {/* Modal Header */}
          <div className="p-4 bg-slate-900/50 flex items-start justify-between shrink-0 border-b border-slate-700">
            <div>
              <div className="flex items-center gap-2">
                <span className="text-2xl">{getCategoryIcon(ticket.category)}</span>
                <h2 className="text-xl font-bold text-white">
                  {ticket.title}
                </h2>
              </div>
              <div className="flex items-center gap-3 mt-2">
                <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(ticket.status)}`}>
                  {ticket.status}
                </span>
                <span className={`flex items-center gap-1 text-sm ${getPriorityColor(ticket.priority)}`}>
                  <FaCircle className="h-2 w-2" />
                  <span className="capitalize">{ticket.priority} Priority</span>
                </span>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors"
            >
              <FaTimes className="h-5 w-5 text-slate-400 hover:text-white" />
            </button>
          </div>

          {/* Initial Ticket Message */}
          <div className="shrink-0 p-4 border-b border-slate-700">
            <div className="bg-slate-900/30 rounded-xl p-4">
              <div className="flex items-center gap-2 mb-2">
                <img 
                  src={ticket.userPhotoURL || 'https://via.placeholder.com/32'} 
                  alt={ticket.userDisplayName}
                  className="w-8 h-8 rounded-full"
                />
                <div>
                  <p className="text-sm font-medium text-white">{ticket.userDisplayName}</p>
                  <p className="text-xs text-slate-400">{formatTimestamp(ticket.createdAt)}</p>
                </div>
              </div>
              <p className="text-slate-300 text-sm mt-2">{ticket.description}</p>
            </div>
          </div>

          {/* Chat Box */}
          <div className="flex-grow flex flex-col min-h-0">
            <ChatBox
              ticketId={ticket.id}
              messages={messages}
              loading={loading}
            />
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

const CreateTicketModal: React.FC<{
  onClose: () => void;
  onSuccess: () => void;
}> = ({ onClose, onSuccess }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState<'technical' | 'billing' | 'general' | 'other'>('technical');
  const [priority, setPriority] = useState<'low' | 'medium' | 'high'>('medium');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const user = auth.currentUser;
      if (!user) {
        toast.error('You must be logged in to create a ticket');
        return;
      }

      const ticketData = {
        title,
        description,
        category,
        priority,
        status: 'open',
        userId: user.uid,
        userName: user.email,
        userDisplayName: user.displayName || user.email?.split('@')[0] || 'Anonymous',
        userPhotoURL: user.photoURL || null,
        isPrivate: true, // Always private
        createdAt: serverTimestamp(),
        lastUpdated: serverTimestamp(),
        unreadAdmin: true,
        unreadUser: false,
        lastMessage: description,
        lastMessageTime: serverTimestamp()
      };

      // Create the ticket in support_tickets collection
      const ticketRef = await addDoc(collection(db, 'support_tickets'), ticketData);

      // Create initial message in the messages subcollection
      await addDoc(collection(db, 'support_tickets', ticketRef.id, 'messages'), {
        content: description,
        userId: user.uid,
        userName: user.email,
        userDisplayName: user.displayName || user.email?.split('@')[0] || 'Anonymous',
        userPhotoURL: user.photoURL || null,
        createdAt: serverTimestamp(),
        isSystem: false
      });

      toast.success('Support ticket created successfully');
      onSuccess();
      onClose();
    } catch (error) {
      console.error('Error creating ticket:', error);
      toast.error('Failed to create ticket');
    } finally {
      setSubmitting(false);
    }
  };

  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.95,
      y: 20
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      y: 20,
      transition: {
        duration: 0.2
      }
    }
  };

  return (
    <motion.div
      className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className="bg-slate-800 rounded-2xl w-full max-w-2xl overflow-hidden"
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={e => e.stopPropagation()}
      >
        <div className="p-6 bg-slate-900/50 flex items-center justify-between">
          <h2 className="text-2xl font-bold text-white">Create Support Ticket</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors"
          >
            <FaTimes className="h-5 w-5 text-slate-400 hover:text-white" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="space-y-2">
            <label className="text-sm font-medium text-slate-300">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-4 py-2 bg-slate-900/50 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 text-white"
              placeholder="Brief description of the issue"
              required
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-slate-300">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-4 py-2 bg-slate-900/50 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 text-white h-32 resize-none"
              placeholder="Detailed description of your issue"
              required
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Category</label>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value as any)}
                className="w-full px-4 py-2 bg-slate-900/50 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 text-white"
              >
                <option value="technical">Technical</option>
                <option value="billing">Billing</option>
                <option value="general">General</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Priority</label>
              <select
                value={priority}
                onChange={(e) => setPriority(e.target.value as any)}
                className="w-full px-4 py-2 bg-slate-900/50 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 text-white"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-slate-400 hover:text-white transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="px-6 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
            >
              {submitting ? (
                <>
                  <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                  <span>Creating...</span>
                </>
              ) : (
                <>
                  <FaPlus className="w-4 h-4" />
                  <span>Create Ticket</span>
                </>
              )}
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

const Support = () => {
  const [tickets, setTickets] = useState<SupportTicket[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<SupportTicket | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/login');
      return;
    }

    // Listen for back/forward button clicks
    const handlePopState = () => {
      setSelectedTicket(null);
    };
    window.addEventListener('popstate', handlePopState);

    // Query with ordering (uncomment after index is created)
    const ticketsRef = collection(db, 'support_tickets');
    const q = query(
      ticketsRef,
      where('userId', '==', user.uid),
      orderBy('createdAt', 'desc')  // Add this back after creating the index
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const ticketData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as SupportTicket[];
      setTickets(ticketData);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching tickets:', error);
      toast.error('Error loading tickets');
      setLoading(false);
    });

    return () => {
      unsubscribe();
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const handleTicketClick = (ticket: SupportTicket) => {
    setSelectedTicket(ticket);
    // Add a new history entry when opening a ticket
    window.history.pushState(null, '', `?ticket=${ticket.id}`);
  };

  const handleCloseTicket = () => {
    setSelectedTicket(null);
    // Go back when closing the ticket
    window.history.back();
  };

  const handleCreateSuccess = () => {
    setShowCreateModal(false);
    toast.success('Ticket created successfully');
  };

  // Update ticket read status when user views it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTicketRead = async (ticket: SupportTicket) => {
    if (ticket.unreadUser) {
      try {
        await updateDoc(doc(db, 'support_tickets', ticket.id), {
          unreadUser: false
        });
      } catch (error) {
        console.error('Error updating ticket read status:', error);
      }
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15,
        duration: 0.4
      }
    },
    hover: {
      y: -5,
      scale: 1.02,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800 text-slate-300 p-6">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-7xl mx-auto space-y-6"
      >
        {/* Header Section */}
        <div className="flex justify-between items-center bg-slate-800/50 p-6 rounded-xl backdrop-blur-sm">
          <div>
            <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-emerald-400 to-blue-400">
              My Support Tickets
            </h1>
            <p className="text-slate-400 mt-1">View and manage your support requests</p>
          </div>
          <div className="flex items-center gap-4">
            <div className="px-4 py-2 bg-slate-700/50 rounded-lg">
              <span className="text-emerald-400 font-semibold">{tickets.length}</span>
              <span className="text-slate-400 ml-2">Total</span>
            </div>
            <button
              onClick={() => setShowCreateModal(true)}
              className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors flex items-center gap-2"
            >
              <FaPlus className="w-4 h-4" />
              <span>New Ticket</span>
            </button>
          </div>
        </div>

        {/* Tickets List */}
        <AnimatePresence>
          <motion.div
            variants={containerVariants}
            className="space-y-4"
          >
            {tickets.map((ticket) => (
              <motion.div
                key={ticket.id}
                variants={itemVariants}
                whileHover="hover"
                className="bg-slate-800/50 rounded-xl p-6 backdrop-blur-sm hover:bg-slate-800/70 transition-all duration-300 group cursor-pointer"
                onClick={() => handleTicketClick(ticket)}
              >
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0">
                    <div className="text-3xl">
                      {getCategoryIcon(ticket.category)}
                    </div>
                  </div>
                  <div className="flex-grow">
                    <div className="flex items-center gap-3 mb-2">
                      <h2 className="text-lg font-semibold text-white">
                        {ticket.title}
                      </h2>
                      <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(ticket.status)}`}>
                        {ticket.status}
                      </span>
                    </div>
                    <p className="text-slate-400 mb-4 line-clamp-2">
                      {ticket.description}
                    </p>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-6 text-sm text-slate-400">
                        <div className="flex items-center gap-2">
                          <FaUser className="h-4 w-4" />
                          <span>{ticket.userName}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <FaClock className="h-4 w-4" />
                          <span>Updated: {formatTimestamp(ticket.lastUpdated)}</span>
                        </div>
                      </div>
                      <button
                        className="p-2 text-slate-400 hover:text-white transition-colors opacity-0 group-hover:opacity-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTicketClick(ticket);
                        }}
                      >
                        <FaExpandAlt className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>

        {/* Empty State */}
        {tickets.length === 0 && !loading && (
          <div className="text-center py-12 bg-slate-800/50 rounded-xl backdrop-blur-sm">
            <FaTicketAlt className="h-12 w-12 text-slate-600 mx-auto mb-4" />
            <p className="text-slate-400 text-lg">You haven't created any support tickets yet</p>
          </div>
        )}

        {/* Ticket Modal */}
        {selectedTicket && (
          <TicketModal
            ticket={selectedTicket}
            onClose={handleCloseTicket}
          />
        )}

        {/* Create Ticket Modal */}
        <AnimatePresence>
          {showCreateModal && (
            <CreateTicketModal
              onClose={() => setShowCreateModal(false)}
              onSuccess={handleCreateSuccess}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default Support;
