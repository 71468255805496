import React, { useRef, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { FaDownload } from 'react-icons/fa';
import { BiRefresh } from 'react-icons/bi';
import { doc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { db, auth, storage } from '../../../config/firebase';
import { useAuth } from '../../../hooks/useAuth';
import { toast } from 'react-hot-toast';
import signImage from '../../../assets/sign.png';
import nirdeshWhite from '../../../assets/nirdesh-white.png';
import regOfficeWhite from '../../../assets/regoffice-white.png';
import adminOfficeWhite from '../../../assets/adminoffice-white.png';
import nirdesh from '../../../assets/nirdesh.png';
import regOffice from '../../../assets/regoffice.png';
import adminOffice from '../../../assets/adminoffice.png';
import { QRCodeSVG } from 'qrcode.react';
// @ts-ignore
import html2canvas from 'html2canvas';

interface UserDetails {
  fullName: string;
  enrollmentNumber: string;
  memberType: string;
  designation: string;
  branch?: string;
  phone: string;
  currentState: string;
  currentDistrict: string;
  currentCityVillage: string;
  currentPinCode: string;
  dateOfBirth: string;
  bloodGroup: string;
  validUntil: string;
  photoURL?: string;
  photo?: string;
  fatherOrHusbandName?: string;
}

const Downloads = () => {
  const [userDetails, setUserDetails] = React.useState<UserDetails | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const cardFrontRef = useRef<HTMLDivElement>(null);
  const cardBackRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const fetchMemberDetails = useCallback(async (userId: string) => {
    setIsLoading(true);
    setError(null);
    try {
      // First try userDetails collection
      const userDetailsRef = doc(db, 'userDetails', userId);
      const userDetailsDoc = await getDoc(userDetailsRef);
      
      let data;
      if (userDetailsDoc.exists()) {
        data = userDetailsDoc.data();
        console.log('Found user in userDetails:', data);
      } else {
        // Try users collection
        const usersRef = doc(db, 'users', userId);
        const usersDoc = await getDoc(usersRef);
        
        if (usersDoc.exists()) {
          data = usersDoc.data();
          console.log('Found user in users:', data);
        } else {
          console.log('No user document found in any collection');
          setError('No member details found');
          return;
        }
      }

      // Get photo URL if it exists in storage
      let photoURL = '';
      
      // Try getting from data fields first
      if (data.photoURL) {
        console.log('Found photoURL in data:', data.photoURL);
        photoURL = data.photoURL;
      } else if (data.photo) {
        console.log('Found photo in data:', data.photo);
        photoURL = data.photo;
      }
      
      // If no URL in data, try storage
      if (!photoURL) {
        try {
          // Try with userId first
          let photoRef = ref(storage, `users/${userId}/profile.jpg`);
          try {
            photoURL = await getDownloadURL(photoRef);
            console.log('Got photo URL from storage with userId:', photoURL);
          } catch {
            // Try with data.uid if different
            if (data.uid && data.uid !== userId) {
              photoRef = ref(storage, `users/${data.uid}/profile.jpg`);
              photoURL = await getDownloadURL(photoRef);
              console.log('Got photo URL from storage with data.uid:', photoURL);
            }
          }
        } catch (error) {
          console.log('No photo in storage:', error);
        }
      }

      console.log('Final photo URL:', photoURL);

      setUserDetails({
        fullName: data.fullName || '',
        enrollmentNumber: data.enrollmentNumber || '',
        memberType: data.memberType || '',
        designation: data.designation || '',
        branch: data.branch || '',
        phone: data.phone || '',
        currentState: data.currentState || '',
        currentDistrict: data.currentDistrict || '',
        currentCityVillage: data.currentCityVillage || '',
        currentPinCode: data.currentPinCode || '',
        dateOfBirth: data.dateOfBirth || '',
        bloodGroup: data.bloodGroup || '',
        validUntil: data.validUntil || '',
        photoURL: photoURL,
        fatherOrHusbandName: data.fatherOrHusbandName || ''
      });
    } catch (error) {
      console.error('Error fetching user details:', error);
      setError('Failed to fetch user details');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        console.log('Current user ID:', currentUser.uid);
        fetchMemberDetails(currentUser.uid);
      }
    });

    return () => unsubscribe();
  }, [fetchMemberDetails]);

  const capitalizeWords = (str: string) => {
    return str.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    if (!year || !month || !day) return dateString;
    return `${day}/${month}/${year}`;
  };

  const handleMembershipCardDownload = async (side: 'front' | 'back') => {
    try {
      const cardElement = side === 'front' ? cardFrontRef.current : cardBackRef.current;
      
      if (!cardElement) {
        console.error('Card element not found');
        return;
      }

      // Create canvas
      const canvas = await html2canvas(cardElement, {
        scale: 2,
        backgroundColor: null,
        useCORS: true,
        allowTaint: true,
        logging: false,
      });

      // Create a temporary canvas with adjusted height
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      tempCanvas.width = canvas.width;
      tempCanvas.height = canvas.height - 70;

      // Draw the original canvas content onto the temporary canvas
      if (tempCtx) {
        tempCtx.drawImage(
          canvas,
          0, 0, canvas.width, canvas.height,
          0, 0, tempCanvas.width, tempCanvas.height
        );
      }

      // Create filename with enrollment number and date
      const fileName = `CCRO_ID_${userDetails?.enrollmentNumber || 'card'}_${side}_${formatDate(userDetails?.validUntil || '')}.png`;

      // Convert to blob and download
      tempCanvas.toBlob((blob) => {
        if (blob) {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      }, 'image/png');

    } catch (err: unknown) {
      const error = err as Error;
      console.error('Error generating card:', error);
      toast.error('Failed to generate card');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !userDetails) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-400 mb-4">{error || 'No member details found'}</p>
          <button
            onClick={() => window.location.reload()}
            className="inline-flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90"
          >
            <BiRefresh className="mr-2" />
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="p-6"
    >
      <div className="space-y-6">
        {/* Membership Card Section */}
        <div className="space-y-6">
          <h2 className="text-2xl font-bold text-white">
            Membership Card
          </h2>

          {/* Cards Container */}
          <div className="flex justify-start items-start gap-8 min-h-[400px] p-4 flex-wrap">
            {/* Gradient Card - Show for specific member types */}
            {userDetails?.memberType && ['National', 'Range', 'Centre Zone', 'North Zone', 'East Zone', 'West Zone', 'South Zone', 'State', 'District', 'City', 'Woman'].includes(userDetails.memberType) && (
              <>
                {/* Card Front with Download Button */}
                <div className="flex flex-col items-center gap-4">
                  <motion.div
                    ref={cardFrontRef}
                    className="relative w-[600px] h-[378px] rounded-xl overflow-hidden shadow-2xl"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    {/* Card Background */}
                    <div className="absolute inset-0">
                      <div 
                        className="absolute inset-0"
                        style={{ 
                          background: 'linear-gradient(to left bottom, #dc2626 49.9%, #1e3a8a 50%)',
                          width: '100%',
                          height: '100%'
                        }}
                      />
                      {/* White transparent overlay with padding */}
                      <div className="absolute inset-3">
                        <div className="w-full h-full bg-black/50 rounded-lg" />
                      </div>
                      {/* Overlay for better text readability */}
                      <div className="absolute inset-0 bg-black/10" />
                    </div>

                    {/* Card Content */}
                    <div className="relative h-full z-10">
                      {/* Logo */}
                      <img 
                        src="/images/logo.png" 
                        alt="Logo" 
                        className="h-40 w-auto object-contain absolute top-2 left-2"
                      />

                      {/* User Photo */}
                      {userDetails?.photoURL && (
                        <div className="absolute bottom-1 right-12 top-40 w-36 h-44 rounded-lg overflow-hidden border-2 border-white/50">
                          <img
                            src={userDetails.photoURL}
                            alt="User"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}

                      {/* Company Seal */}
                      <div className="absolute right-36 bottom-3 w-24 h-24">
                        <img
                          src={signImage}
                          alt="Company Seal"
                          className="w-full h-full object-contain"
                          onError={(e) => {
                            console.error('Error loading seal:', e);
                          }}
                        />
                      </div>

                      {/* User Details Section */}
                      <div className="absolute top-44 left-8 text-white">
                        <div className="flex flex-col space-y-0.5 text-sm">
                          <div>
                            <span className="font-semibold">Name: </span>
                            <span className="text-white font-bold">{capitalizeWords(userDetails.fullName)}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Father/Spouse: </span>
                            <span className="text-white font-bold">{capitalizeWords(userDetails.fatherOrHusbandName || '')}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Phone: </span>
                            <span className="text-white font-bold">{userDetails.phone}</span>
                            <span className="font-semibold ml-4">DOB: </span>
                            <span className="text-white font-bold">{userDetails.dateOfBirth}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Address: </span>
                            <span className="text-white font-bold">
                              {`${capitalizeWords(userDetails.currentCityVillage)}, ${capitalizeWords(userDetails.currentDistrict)}, ${capitalizeWords(userDetails.currentState)} - ${userDetails.currentPinCode}`}
                            </span>
                          </div>
                          <div>
                            <span className="font-semibold">Blood Group: </span>
                            <span className="text-white font-bold">{userDetails.bloodGroup}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Enroll: </span>
                            <span className="text-white font-bold">{userDetails.enrollmentNumber}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Designation: </span>
                            <span className="text-white font-bold">{`${capitalizeWords(userDetails.memberType)} ${capitalizeWords(userDetails.designation)}`}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Branch: </span>
                            <span className="text-white font-bold">{capitalizeWords(userDetails.branch || '')}</span>
                            <span className="font-semibold ml-4">Validity: </span>
                            <span className="text-white font-bold">{formatDate(userDetails.validUntil)}</span>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col items-center text-center px-20 pt-6">
                        {/* Top Group - No gap */}
                        <div className="space-y-0.5 mb-2">
                          {/* Line 1 - Registration details */}
                          <p className="text-[8px] text-white tracking-tighter whitespace-nowrap leading-none ml-32 max-w-[400px] font-medium">
                            Regd.No.:1142/12 TM No.:2312630-45 CR No.:4459/2012-CO-A, ISO 9001-2015 NGO DARPAN:DL/2020/0252031
                          </p>

                          {/* Line 2 - English Name */}
                          <h1 className="text-xl font-bold text-white tracking-tight whitespace-nowrap leading-none ml-28">
                            CRIME CONTROL & RESEARCH ORGANIZATION
                          </h1>
                        </div>

                        {/* Line 3 - Hindi Name */}
                        <h2 className="text-xl font-bold text-white tracking-tight whitespace-nowrap leading-none ml-28 mb-2">
                          अपराध नियंत्रण एवं अनुसंधान संगठन
                        </h2>

                        {/* Line 4 - Activities */}
                        <p className="text-xs text-white tracking-tight whitespace-nowrap leading-none ml-28 mb-2">
                          Social Welfare, Anti Crime, Human Law, & Charitable Activities
                        </p>

                        {/* Line 5 - Government Registration */}
                        <p className="text-xs font-extrabold text-white whitespace-nowrap leading-none ml-28 mb-2">
                          Regd By: Government Of India (NITI AAYOG)
                        </p>

                        {/* Line 6 - Membership Note */}
                        <p className="text-xs text-white tracking-tight whitespace-nowrap leading-none ml-28">
                          Organization Membership is life time designation are valid in your activities.
                        </p>
                      </div>
                    </div>

                    {/* Card Border */}
                    <div className="absolute inset-0 border border-white/10 rounded-xl pointer-events-none" />
                  </motion.div>
                  
                  <button
                    onClick={() => handleMembershipCardDownload('front')}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition-colors duration-200 flex items-center gap-2"
                  >
                    <FaDownload className="w-5 h-5" />
                    Download Front
                  </button>
                </div>

                {/* Card Back with Download Button */}
                <div className="flex flex-col items-center gap-4">
                  <motion.div
                    ref={cardBackRef}
                    className="relative w-[600px] h-[378px] rounded-xl overflow-hidden shadow-2xl"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    {/* Card Background */}
                    <div className="absolute inset-0">
                      <div 
                        className="absolute inset-0"
                        style={{ 
                          background: 'linear-gradient(to left bottom, #dc2626 49.9%, #1e3a8a 50%)',
                          width: '100%',
                          height: '100%'
                        }}
                      />
                      {/* White transparent overlay with padding */}
                      <div className="absolute inset-3">
                        <div className="w-full h-full bg-black/50 rounded-lg" />
                      </div>
                      {/* Overlay for better text readability */}
                      <div className="absolute inset-0 bg-black/10" />
                    </div>

                    {/* Card Content */}
                    <div className="relative h-full z-10 flex flex-col justify-between p-8 text-white">
                      {/* Top Row */}
                      <div className="w-full flex justify-between items-start">
                        {/* QR Code - Top Left */}
                        <div className="p-3 bg-white rounded-lg shadow-lg">
                          <QRCodeSVG
                            value={`Name: ${userDetails?.fullName || '-'}
                            Father/Spouse: ${userDetails?.fatherOrHusbandName || '-'}
                            Phone: ${userDetails?.phone || '-'}
                            Email: ${user?.email || '-'}
                            DOB: ${userDetails?.dateOfBirth || '-'}
                            Address: ${userDetails?.currentCityVillage || '-'}, ${userDetails?.currentDistrict || '-'}, ${userDetails?.currentState || '-'}-${userDetails?.currentPinCode || '-'}
                            Blood Group: ${userDetails?.bloodGroup || '-'}
                            Enrollment: ${userDetails?.enrollmentNumber || '-'}
                            Designation: ${userDetails?.designation || '-'}
                            Branch: ${userDetails?.branch || '-'}
                            Validity: ${userDetails?.validUntil || '-'}`}
                                                        size={180}
                                                        level="H"
                                                        includeMargin={true}
                                                      />
                        </div>

                        {/* C.C.R.O and Logo - Top Right */}
                        <div className="flex items-center gap-6">
                          {/* C.C.R.O Text */}
                          <div className="flex items-center gap-2 text-5xl font-bold tracking-widest text-white">
                            <span>C.C.R.O</span>
                          </div>
                          {/* Logo */}
                          <img 
                            src="/images/logo.png" 
                            alt="Logo" 
                            className="h-24 w-auto"
                          />
                        </div>
                      </div>

                      {/* Nirdesh Image - Right Aligned */}
                      <div className="absolute top-1/2 right-8 transform -translate-y-1/2 my-0">
                        <img 
                          src={nirdeshWhite}
                          alt="Nirdesh"
                          className="h-28 w-auto object-contain"
                        />
                      </div>

                      {/* Bottom Row */}
                      <div className="w-full flex justify-between items-end">
                        {/* Admin Office - Bottom Left */}
                        <div>
                          <img 
                            src={adminOfficeWhite}
                            alt="Admin Office"
                            className="h-24 w-auto"
                          />
                        </div>

                        {/* Reg Office - Bottom Right */}
                        <div>
                          <img 
                            src={regOfficeWhite}
                            alt="Reg Office"
                            className="h-24 w-auto"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Card Border */}
                    <div className="absolute inset-0 border border-white/10 rounded-xl pointer-events-none" />
                  </motion.div>
                  <button
                    onClick={() => handleMembershipCardDownload('back')}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition-colors duration-200 flex items-center gap-2"
                  >
                    <FaDownload className="w-5 h-5" />
                    Download Back
                  </button>
                </div>
              </>
            )}

            {/* White Card - Show only for Member type */}
            {userDetails?.memberType === 'Member' && (
              <>
                {/* Card Front with Download Button */}
                <div className="flex flex-col items-center gap-4">
                  <motion.div
                    ref={cardFrontRef}
                    className="relative w-[600px] h-[378px] bg-white rounded-xl overflow-hidden shadow-2xl"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    {/* Card Content */}
                    <div className="relative h-full z-10">
                      {/* Logo */}
                      <img 
                        src="/images/logo.png" 
                        alt="Logo" 
                        className="h-40 w-auto object-contain absolute top-2 left-2"
                      />

                      {/* User Photo */}
                      {userDetails?.photoURL && (
                        <div className="absolute bottom-1 right-12 top-40 w-36 h-44 rounded-lg overflow-hidden border-2 border-gray-300">
                          <img
                            src={userDetails.photoURL}
                            alt="User"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}

                      {/* Company Seal */}
                      <div className="absolute right-36 bottom-3 w-24 h-24">
                        <img
                          src={signImage}
                          alt="Company Seal"
                          className="w-full h-full object-contain"
                          onError={(e) => {
                            console.error('Error loading seal:', e);
                          }}
                        />
                      </div>

                      {/* User Details Section */}
                      <div className="absolute top-44 left-8 text-blue-900">
                        <div className="flex flex-col space-y-0.5 text-sm">
                          <div>
                            <span className="font-semibold">Name: </span>
                            <span className="text-blue-900">{capitalizeWords(userDetails.fullName)}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Father/Spouse: </span>
                            <span className="text-blue-900">{capitalizeWords(userDetails.fatherOrHusbandName || '')}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Phone: </span>
                            <span className="text-blue-900">{userDetails.phone}</span>
                            <span className="font-semibold ml-4">DOB: </span>
                            <span className="text-blue-900">{userDetails.dateOfBirth}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Address: </span>
                            <span className="text-blue-900">
                              {`${capitalizeWords(userDetails.currentCityVillage)}, ${capitalizeWords(userDetails.currentDistrict)}, ${capitalizeWords(userDetails.currentState)} - ${userDetails.currentPinCode}`}
                            </span>
                          </div>
                          <div>
                            <span className="font-semibold">Blood Group: </span>
                            <span className="text-blue-900">{userDetails.bloodGroup}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Enroll: </span>
                            <span className="text-blue-900">{userDetails.enrollmentNumber}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Designation: </span>
                            <span className="text-blue-900">{`${capitalizeWords(userDetails.memberType)} ${capitalizeWords(userDetails.designation)}`}</span>
                          </div>
                          <div>
                            <span className="font-semibold">Branch: </span>
                            <span className="text-blue-900">{capitalizeWords(userDetails.branch || '')}</span>
                            <span className="font-semibold ml-4">Validity: </span>
                            <span className="text-blue-900">{formatDate(userDetails.validUntil)}</span>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col items-center text-center px-20 pt-6">
                        {/* Top Group - No gap */}
                        <div className="space-y-0.5 mb-2">
                          {/* Line 1 - Registration details */}
                          <p className="text-[8px] text-blue-900 tracking-tighter whitespace-nowrap leading-none ml-32 max-w-[400px] font-medium">
                            Regd.No.:1142/12 TM No.:2312630-45 CR No.:4459/2012-CO-A, ISO 9001-2015 NGO DARPAN:DL/2020/0252031
                          </p>

                          {/* Line 2 - English Name */}
                          <h1 className="text-xl font-bold text-red-600 tracking-tight whitespace-nowrap leading-none ml-28">
                            CRIME CONTROL & RESEARCH ORGANIZATION
                          </h1>
                        </div>

                        {/* Line 3 - Hindi Name */}
                        <h2 className="text-xl font-bold text-blue-900 tracking-tight whitespace-nowrap leading-none ml-28 mb-2">
                          अपराध नियंत्रण एवं अनुसंधान संगठन
                        </h2>

                        {/* Line 4 - Activities */}
                        <p className="text-xs text-blue-900 tracking-tight whitespace-nowrap leading-none ml-28 mb-2">
                          Social Welfare, Anti Crime, Human Law, & Charitable Activities
                        </p>

                        {/* Line 5 - Government Registration */}
                        <p className="text-xs font-extrabold text-blue-900 whitespace-nowrap leading-none ml-28 mb-2">
                          Regd By: Government Of India (NITI AAYOG)
                        </p>

                        {/* Line 6 - Membership Note */}
                        <p className="text-xs text-blue-900 tracking-tight whitespace-nowrap leading-none ml-28">
                          Organization Membership is life time designation are valid in your activities.
                        </p>
                      </div>
                    </div>
                  </motion.div>
                  <button
                    onClick={() => handleMembershipCardDownload('front')}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition-colors duration-200 flex items-center gap-2"
                  >
                    <FaDownload className="w-5 h-5" />
                    Download Front
                  </button>
                </div>

                {/* Card Back with Download Button */}
                <div className="flex flex-col items-center gap-4">
                  <motion.div
                    ref={cardBackRef}
                    className="relative w-[600px] h-[378px] bg-white rounded-xl overflow-hidden shadow-2xl"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    {/* Card Content */}
                    <div className="relative h-full z-10 flex flex-col justify-between p-8 text-blue-900">
                      {/* Top Row */}
                      <div className="w-full flex justify-between items-start">
                        {/* QR Code - Top Left */}
                        <div className="p-3 bg-white rounded-lg shadow-lg">
                          <QRCodeSVG
                            value={`Name: ${userDetails?.fullName || '-'}
Father/Spouse: ${userDetails?.fatherOrHusbandName || '-'}
Phone: ${userDetails?.phone || '-'}
Email: ${user?.email || '-'}
DOB: ${userDetails?.dateOfBirth || '-'}
Address: ${userDetails?.currentCityVillage || '-'}, ${userDetails?.currentDistrict || '-'}, ${userDetails?.currentState || '-'}-${userDetails?.currentPinCode || '-'}
Blood Group: ${userDetails?.bloodGroup || '-'}
Enrollment: ${userDetails?.enrollmentNumber || '-'}
Designation: ${userDetails?.designation || '-'}
Branch: ${userDetails?.branch || '-'}
Validity: ${userDetails?.validUntil || '-'}`}
                            size={180}
                            level="H"
                            includeMargin={true}
                          />
                        </div>

                        {/* C.C.R.O and Logo - Top Right */}
                        <div className="flex items-center gap-6">
                          {/* C.C.R.O Text */}
                          <div className="flex items-center gap-2 text-5xl font-bold tracking-widest text-blue-900">
                            <span>C.C.R.O</span>
                          </div>
                          {/* Logo */}
                          <img 
                            src="/images/logo.png" 
                            alt="Logo" 
                            className="h-24 w-auto"
                          />
                        </div>
                      </div>

                      {/* Nirdesh Image - Right Aligned */}
                      <div className="absolute top-1/2 right-8 transform -translate-y-1/2 my-0">
                        <img 
                          src={nirdesh}
                          alt="Nirdesh"
                          className="h-28 w-auto object-contain"
                        />
                      </div>

                      {/* Bottom Row */}
                      <div className="w-full flex justify-between items-end">
                        {/* Admin Office - Bottom Left */}
                        <div>
                          <img 
                            src={adminOffice}
                            alt="Admin Office"
                            className="h-24 w-auto"
                          />
                        </div>

                        {/* Reg Office - Bottom Right */}
                        <div>
                          <img 
                            src={regOffice}
                            alt="Reg Office"
                            className="h-24 w-auto"
                          />
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <button
                    onClick={() => handleMembershipCardDownload('back')}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition-colors duration-200 flex items-center gap-2"
                  >
                    <FaDownload className="w-5 h-5" />
                    Download Back
                  </button>
                </div>
              </>
            )}
          </div>

          {/* Download Button */}
          <div className="flex justify-center">
            {/* Removed download button */}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Downloads;
