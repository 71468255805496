import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import LockedContent from './LockedContent';

interface MembershipProtectedRouteProps {
  children: React.ReactNode;
}

const MembershipProtectedRoute: React.FC<MembershipProtectedRouteProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isActiveMember, setIsActiveMember] = useState(false);

  useEffect(() => {
    const checkMembership = async () => {
      try {
        const user = auth.currentUser;
        console.log('Current user:', user?.email);

        if (!user) {
          setIsActiveMember(false);
          setIsLoading(false);
          return;
        }

        // Check userDetails collection
        const userDetailsRef = doc(db, 'userDetails', user.uid);
        const userDetailsDoc = await getDoc(userDetailsRef);

        if (userDetailsDoc.exists()) {
          const userDetails = userDetailsDoc.data();
          console.log('User details:', userDetails);
          // Changed from membershipStatus to memberStatus
          const isActive = userDetails.memberStatus?.toLowerCase() === 'active';
          console.log('Is active member:', isActive);
          setIsActiveMember(isActive);
        } else {
          console.log('No user details found');
          setIsActiveMember(false);
        }
      } catch (error) {
        console.error('Error checking membership:', error);
        setIsActiveMember(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkMembership();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center bg-gray-900/5 rounded-xl">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Show the actual content with the lock overlay if not an active member
  return (
    <div className="relative">
      {children}
      {!isActiveMember && (
        <div className="absolute inset-0">
          <LockedContent />
        </div>
      )}
    </div>
  );
};

export default MembershipProtectedRoute;
