import React from 'react';
import { motion } from 'framer-motion';
import { FaLock, FaCrown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const LockedContent: React.FC = () => {
  const navigate = useNavigate();

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="absolute inset-0 z-50 overflow-hidden rounded-xl"
    >
      {/* Blur Overlay */}
      <div className="absolute inset-0 backdrop-blur-md bg-gray-900/70" />
      
      {/* Content */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative h-full flex flex-col items-center justify-center p-8 text-center"
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
          className="relative mb-6"
        >
          {/* Glow effect */}
          <div className="absolute inset-0 bg-blue-500/30 rounded-full blur-xl animate-pulse" />
          <div className="relative bg-gradient-to-br from-blue-500 to-purple-600 p-4 rounded-full">
            <FaLock className="text-white w-8 h-8" />
          </div>
        </motion.div>

        <motion.h2
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="text-2xl font-bold text-white mb-4"
        >
          Premium Content Locked
        </motion.h2>

        <motion.p
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="text-gray-300 mb-6 max-w-md"
        >
          This content is exclusively available to our active members. Upgrade your membership to unlock premium features.
        </motion.p>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="space-y-4"
        >
          <button
            onClick={() => navigate('/dashboard/membership')}
            className="px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg font-medium flex items-center justify-center space-x-2 hover:from-blue-600 hover:to-purple-700 transition-all duration-200 shadow-lg hover:shadow-xl group"
          >
            <FaCrown className="w-5 h-5 transform group-hover:scale-110 transition-transform" />
            <span>Upgrade Membership</span>
          </button>
          
          <div>
            <button
              onClick={() => navigate('/dashboard')}
              className="text-gray-400 hover:text-white transition-colors duration-200"
            >
              Return to Dashboard
            </button>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default LockedContent;
