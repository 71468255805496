import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { collection, query, getDocs, orderBy, Timestamp, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../../config/firebase';
import { toast } from 'react-hot-toast';
import { FaArrowLeft, FaClock, FaUser, FaTags, FaEdit, FaGlobe, FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface UserDetails {
  photo: string;
  fullName: string;
  email: string;
  phone: string;
  memberType: string;
  memberStatus: string;
}

interface Article {
  id: string;
  title: string;
  heading: string;
  subHeading: string;
  content: string;
  featuredImage: string;
  authorId: string;
  authorEmail: string;
  authorName?: string;
  category: string;
  tags: string[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

const FullArticleView: React.FC<{
  article: Article;
  onBack: () => void;
}> = ({ article, onBack }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800 text-slate-300 p-6"
    >
      <div className="max-w-4xl mx-auto">
        {/* Back Button */}
        <button
          onClick={onBack}
          className="mb-6 px-4 py-2 flex items-center gap-2 text-slate-400 hover:text-white transition-colors"
        >
          <FaArrowLeft className="w-4 h-4" />
          <span>Back to Articles</span>
        </button>

        {/* Article Header */}
        <div className="bg-slate-800/50 rounded-xl p-8 backdrop-blur-sm mb-6">
          <h1 className="text-4xl font-bold text-white mb-2">
            {article.heading}
          </h1>
          <h2 className="text-xl text-slate-400 mb-4">
            {article.subHeading}
          </h2>
          
          <div className="flex flex-wrap items-center gap-6 text-sm text-slate-400">
            <div className="flex items-center gap-2">
              <FaUser className="w-4 h-4" />
              <span>{article.authorName}</span>
            </div>
            <div className="flex items-center gap-2">
              <FaClock className="w-4 h-4" />
              <span>Published {article.createdAt.toDate().toLocaleDateString()}</span>
            </div>
            {article.tags && article.tags.length > 0 && (
              <div className="flex items-center gap-2">
                <FaTags className="w-4 h-4" />
                <div className="flex gap-2">
                  {article.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-slate-700/50 rounded-full text-xs"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Featured Image */}
        {article.featuredImage && (
          <div className="mb-8 rounded-xl overflow-hidden">
            <img
              src={article.featuredImage}
              alt={article.heading}
              className="w-full h-[400px] object-cover"
            />
          </div>
        )}

        {/* Article Content */}
        <div className="bg-slate-800/50 rounded-xl p-8 backdrop-blur-sm">
          <div 
            className="prose prose-invert max-w-none"
            dangerouslySetInnerHTML={{ __html: article.content }}
          />
        </div>

        {/* Article Footer */}
        <div className="mt-8 p-6 bg-slate-800/50 rounded-xl backdrop-blur-sm">
          <div className="flex items-center justify-between text-sm text-slate-400">
            <div className="flex items-center gap-2">
              <FaClock className="w-4 h-4" />
              <span>Last updated {article.updatedAt.toDate().toLocaleDateString()}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="px-3 py-1 bg-slate-700/50 rounded-full">
                {article.category}
              </span>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const Articles = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [showMyArticles, setShowMyArticles] = useState(false);
  const navigate = useNavigate();

  const fetchAuthorDetails = async (userId: string): Promise<string | undefined> => {
    try {
      console.log('Fetching author details for userId:', userId);

      // First try to get user details from userDetails collection
      const userDetailsRef = doc(db, 'userDetails', userId);
      const userDetailsDoc = await getDoc(userDetailsRef);

      if (userDetailsDoc.exists()) {
        const details = userDetailsDoc.data() as UserDetails;
        console.log('Found in userDetails:', details);
        return details.fullName || details.email;
      }

      // If not found in userDetails, try the users collection
      const usersRef = doc(db, 'users', userId);
      const usersDoc = await getDoc(usersRef);

      if (usersDoc.exists()) {
        const userData = usersDoc.data() as UserDetails;
        console.log('Found in users:', userData);
        return userData.fullName || userData.email;
      }

      // If still not found, try to get the current user's email
      const currentUser = auth.currentUser;
      if (currentUser && currentUser.uid === userId) {
        console.log('Using current user email:', currentUser.email);
        return currentUser.email || undefined;
      }

      console.log('No user details found for:', userId);
      return undefined;
    } catch (error) {
      console.error('Error fetching author details:', error);
      return undefined;
    }
  };

  const fetchArticles = useCallback(async () => {
    try {
      const articlesRef = collection(db, 'articles');
      const q = query(articlesRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      
      const articlesData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt || Timestamp.now()
        };
      }) as Article[];

      // Fetch author details for each article
      const articlesWithAuthors = await Promise.all(
        articlesData.map(async (article) => {
          const authorName = await fetchAuthorDetails(article.authorId);
          return {
            ...article,
            authorName: authorName || 'Unknown Author'
          };
        })
      );

      // Sort by createdAt in descending order (newest first)
      const sortedArticles = articlesWithAuthors.sort((a, b) => 
        b.createdAt.seconds - a.createdAt.seconds
      );

      setArticles(sortedArticles);
    } catch (error) {
      console.error('Error fetching articles:', error);
      toast.error('Failed to fetch articles');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        toast.error('Please login to view articles');
        navigate('/login');
        return;
      }
      fetchArticles();
    });

    return () => unsubscribe();
  }, [navigate, fetchArticles]);

  const filteredArticles = showMyArticles 
    ? articles.filter(article => auth.currentUser && article.authorId === auth.currentUser.uid)
    : articles;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15,
        duration: 0.4
      }
    },
    hover: {
      y: -5,
      scale: 1.02,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  if (selectedArticle) {
    return (
      <FullArticleView
        article={selectedArticle}
        onBack={() => setSelectedArticle(null)}
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-4">
            <div className="px-4 py-2 bg-slate-700/50 rounded-lg">
              <span className="text-emerald-400 font-semibold">{filteredArticles.length}</span>
              <span className="text-slate-400 ml-2">Articles</span>
            </div>
            {filteredArticles.length > 0 && (
              <div className="px-4 py-2 bg-slate-700/50 rounded-lg">
                <span className="text-slate-400">Latest: </span>
                <span className="text-white font-medium">{filteredArticles[0].heading}</span>
              </div>
            )}
          </div>

          {/* Filter Toggle */}
          <div className="flex items-center gap-2 bg-slate-800 rounded-lg p-1">
            <button
              onClick={() => setShowMyArticles(false)}
              className={`flex items-center gap-2 px-4 py-2 rounded-md transition-colors ${
                !showMyArticles 
                  ? 'bg-primary text-white' 
                  : 'text-slate-400 hover:text-white'
              }`}
            >
              <FaGlobe className="w-4 h-4" />
              All Articles
            </button>
            <button
              onClick={() => setShowMyArticles(true)}
              className={`flex items-center gap-2 px-4 py-2 rounded-md transition-colors ${
                showMyArticles 
                  ? 'bg-primary text-white' 
                  : 'text-slate-400 hover:text-white'
              }`}
            >
              <FaUserCircle className="w-4 h-4" />
              My Articles
            </button>
          </div>
        </div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        >
          {filteredArticles.map((article, index) => (
            <motion.div
              key={article.id}
              variants={itemVariants}
              whileHover="hover"
              className={`bg-slate-800 rounded-lg overflow-hidden cursor-pointer transform transition-all duration-300 ${
                index === 0 ? 'md:col-span-2 lg:col-span-3' : ''
              }`}
              onClick={() => setSelectedArticle(article)}
            >
              {index === 0 && (
                <div className="absolute top-4 left-4 z-10">
                  <span className="px-3 py-1 bg-primary text-white text-xs font-medium rounded-full">
                    Latest Article
                  </span>
                </div>
              )}
              
              {/* Article Image */}
              {article.featuredImage && (
                <div className={`relative ${index === 0 ? 'h-64' : 'h-48'} overflow-hidden`}>
                  <img
                    src={article.featuredImage}
                    alt={article.heading}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-slate-900/90 to-transparent" />
                </div>
              )}

              {/* Article Content */}
              <div className="p-6">
                <h2 className={`${index === 0 ? 'text-2xl' : 'text-xl'} font-semibold text-white mb-2 line-clamp-2`}>
                  {article.heading}
                </h2>
                <h3 className="text-slate-400 text-sm mb-3 line-clamp-2">
                  {article.subHeading}
                </h3>

                <div className="flex items-center gap-4 text-sm text-slate-400 mb-4">
                  <div className="flex items-center gap-2">
                    <FaUser className="w-4 h-4" />
                    <span>{article.authorName}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <FaClock className="w-4 h-4" />
                    <span>{article.createdAt.toDate().toLocaleDateString()}</span>
                  </div>
                </div>

                <div
                  className="text-slate-400 line-clamp-3 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: article.content.replace(/<[^>]*>/g, '')
                  }}
                />

                <div className="flex items-center justify-between">
                  {auth.currentUser && article.authorId === auth.currentUser.uid && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/dashboard/edit-article/${article.id}`);
                      }}
                      className="inline-flex items-center px-3 py-1 bg-emerald-500/20 text-emerald-400 rounded-full text-xs hover:bg-emerald-500/30 transition-colors"
                    >
                      <FaEdit className="w-3 h-3 mr-1" />
                      Edit Article
                    </button>
                  )}
                  {article.tags && article.tags.length > 0 && (
                    <div className="flex gap-2">
                      {article.tags.slice(0, 2).map((tag, index) => (
                        <span
                          key={index}
                          className="px-2 py-1 bg-slate-700/50 rounded-full text-xs"
                        >
                          {tag}
                        </span>
                      ))}
                      {article.tags.length > 2 && (
                        <span className="px-2 py-1 bg-slate-700/50 rounded-full text-xs">
                          +{article.tags.length - 2}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* Empty State */}
        {filteredArticles.length === 0 && (
          <div className="text-center py-12 bg-slate-800/50 rounded-xl backdrop-blur-sm">
            <div className="text-6xl mb-4">📚</div>
            <p className="text-slate-400 text-lg">No articles published yet</p>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default Articles;
