import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';

interface UserDetails {
  photo: string;
  fullName: string;
  email: string;
  phone: string;
  enrollmentNumber: string;
  memberType: string;
  memberStatus: string;
  designation: string;
  validFrom: string;
  validUntil: string;
  dateOfBirth: string;
  gender: string;
  bloodGroup: string;
  education: string;
  profession: string;
  fatherOrHusbandName: string;
  languagesKnown: string;
  currentAddressLine1: string;
  currentAddressLine2: string;
  currentCityVillage: string;
  currentDistrict: string;
  currentState: string;
  currentPinCode: string;
  currentStdCode: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleRegistration: string;
  aadhaarFront: string;
  aadhaarBack: string;
}

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Check for login success message
    const showLoginSuccess = sessionStorage.getItem('showLoginSuccess');
    if (showLoginSuccess === 'true') {
      toast.success('Successfully logged in!');
      sessionStorage.removeItem('showLoginSuccess'); // Clear the flag
    }
  }, []);

  const fetchUserDetails = async (userId: string) => {
    try {
      // First try to get user details from userDetails collection
      const userDetailsRef = doc(db, 'userDetails', userId);
      const userDetailsDoc = await getDoc(userDetailsRef);

      if (userDetailsDoc.exists()) {
        const details = userDetailsDoc.data() as UserDetails;
        return details;
      }

      // If not found in userDetails, try the users collection
      const usersRef = doc(db, 'users', userId);
      const usersDoc = await getDoc(usersRef);

      if (usersDoc.exists()) {
        const userData = usersDoc.data() as UserDetails;
        return userData;
      }

      throw new Error('User data not found in any collection');
    } catch (error) {
      console.error('Error fetching user details:', error);
      throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const userData = await fetchUserDetails(user.uid);

        if (!userData) {
          toast.error('User data not found');
          auth.signOut();
          navigate('/login');
          return;
        }

        // Set default photo if not present
        if (!userData.photo) {
          userData.photo = '/images/default-avatar.png';
        }

        // Validate user status
        if (userData.memberStatus === 'disabled') {
          toast.error('Your account has been disabled. Please contact support.');
          auth.signOut();
          navigate('/login');
          return;
        }

        if (userData.memberStatus === 'pending') {
          toast.error('Your account is pending approval.');
          auth.signOut();
          navigate('/login');
          return;
        }

        setUserDetails(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast.error('Error loading user data');
        auth.signOut();
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  if (!userDetails) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800">
        <div className="text-white">No user details found.</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800 text-slate-300 p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Profile Header */}
        <div className="bg-slate-800/50 rounded-lg p-6 flex flex-col md:flex-row items-center gap-6">
          <div className="relative w-40 h-40">
            <img
              src={userDetails.photo || '/images/default-avatar.png'}
              alt={userDetails.fullName}
              className="rounded-full w-full h-full object-cover border-4 border-emerald-500"
              onError={(e) => {
                const img = e.target as HTMLImageElement;
                img.src = '/images/default-avatar.png';
              }}
            />
            <div className="absolute -bottom-2 -right-2 bg-emerald-500 text-white px-3 py-1 rounded-full text-sm">
              {userDetails.memberStatus}
            </div>
          </div>
          <div className="text-center md:text-left">
            <h1 className="text-3xl font-bold text-emerald-400">{userDetails.fullName}</h1>
            <p className="text-lg text-slate-400">{userDetails.designation}</p>
            <p className="text-sm text-slate-500">Member ID: {userDetails.enrollmentNumber}</p>
            <div className="mt-2 space-x-2">
              <span className="inline-block bg-slate-700 px-3 py-1 rounded-full text-sm">
                {userDetails.memberType}
              </span>
              <span className="inline-block bg-slate-700 px-3 py-1 rounded-full text-sm">
                Valid until: {userDetails.validUntil}
              </span>
            </div>
          </div>
        </div>

        {/* Personal Details */}
        <div className="bg-slate-800/50 rounded-lg p-6">
          <h2 className="text-xl font-semibold text-emerald-400 mb-4">Personal Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div>
              <p className="text-slate-400">Date of Birth</p>
              <p>{userDetails.dateOfBirth}</p>
            </div>
            <div>
              <p className="text-slate-400">Gender</p>
              <p>{userDetails.gender}</p>
            </div>
            <div>
              <p className="text-slate-400">Blood Group</p>
              <p>{userDetails.bloodGroup}</p>
            </div>
            <div>
              <p className="text-slate-400">Education</p>
              <p>{userDetails.education}</p>
            </div>
            <div>
              <p className="text-slate-400">Profession</p>
              <p>{userDetails.profession}</p>
            </div>
            <div>
              <p className="text-slate-400">Father's/Husband's Name</p>
              <p>{userDetails.fatherOrHusbandName}</p>
            </div>
            <div className="col-span-full">
              <p className="text-slate-400">Languages Known</p>
              <p>{userDetails.languagesKnown}</p>
            </div>
          </div>
        </div>

        {/* Contact & Address */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-slate-800/50 rounded-lg p-6">
            <h2 className="text-xl font-semibold text-emerald-400 mb-4">Contact Information</h2>
            <div className="space-y-4">
              <div>
                <p className="text-slate-400">Email</p>
                <p>{userDetails.email}</p>
              </div>
              <div>
                <p className="text-slate-400">Phone</p>
                <p>{userDetails.phone}</p>
              </div>
              <div>
                <p className="text-slate-400">STD Code</p>
                <p>{userDetails.currentStdCode}</p>
              </div>
            </div>
          </div>

          <div className="bg-slate-800/50 rounded-lg p-6">
            <h2 className="text-xl font-semibold text-emerald-400 mb-4">Current Address</h2>
            <div className="space-y-4">
              <div>
                <p className="text-slate-400">Address Line 1</p>
                <p>{userDetails.currentAddressLine1}</p>
              </div>
              {userDetails.currentAddressLine2 && (
                <div>
                  <p className="text-slate-400">Address Line 2</p>
                  <p>{userDetails.currentAddressLine2}</p>
                </div>
              )}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-slate-400">City/Village</p>
                  <p>{userDetails.currentCityVillage}</p>
                </div>
                <div>
                  <p className="text-slate-400">District</p>
                  <p>{userDetails.currentDistrict}</p>
                </div>
                <div>
                  <p className="text-slate-400">State</p>
                  <p>{userDetails.currentState}</p>
                </div>
                <div>
                  <p className="text-slate-400">PIN Code</p>
                  <p>{userDetails.currentPinCode}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Vehicle Details */}
        {(userDetails.vehicleMake || userDetails.vehicleModel || userDetails.vehicleRegistration) && (
          <div className="bg-slate-800/50 rounded-lg p-6">
            <h2 className="text-xl font-semibold text-emerald-400 mb-4">Vehicle Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <p className="text-slate-400">Make</p>
                <p>{userDetails.vehicleMake}</p>
              </div>
              <div>
                <p className="text-slate-400">Model</p>
                <p>{userDetails.vehicleModel}</p>
              </div>
              <div>
                <p className="text-slate-400">Registration Number</p>
                <p>{userDetails.vehicleRegistration}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;