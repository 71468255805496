import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { FiCalendar, FiMapPin, FiUsers } from 'react-icons/fi';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';
import PageTitle from '../../components/PageTitle';

interface Event {
  id: string;
  title: string;
  description: string;
  content: string;
  featuredImage?: {
    url: string;
    alt: string;
  };
  startDate: Timestamp;
  endDate: Timestamp;
  location: string;
  price?: number;
  capacity: number;
  registeredCount: number;
  status: 'draft' | 'published' | 'scheduled';
}

const EventsPage: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const heroRef = useScrollAnimation();
  const eventsRef = useScrollAnimation();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const now = Timestamp.now();
        
        const eventsQuery = query(
          collection(db, 'events'),
          where('status', '==', 'published')
        );

        const querySnapshot = await getDocs(eventsQuery);
        const fetchedEvents = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          } as Event))
          .filter(event => event.endDate.toMillis() >= now.toMillis())
          .sort((a, b) => a.startDate.toMillis() - b.startDate.toMillis());

        setEvents(fetchedEvents);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to load events. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const formatDate = (timestamp: Timestamp) => {
    try {
      return format(timestamp.toDate(), 'MMM d, yyyy h:mm a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date not available';
    }
  };

  const formatPrice = (price: number | undefined) => {
    if (price === undefined || price === 0) return 'Free';
    return `₹${price.toLocaleString('en-IN')}`;
  };

  const isEventFree = (price: number | undefined) => {
    return price === undefined || price === 0;
  };

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-center text-white">
          <p className="text-xl mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <PageTitle
        title="Events & Programs"
        subtitle="Stay updated with our latest events, workshops, and community programs. Join us in making a difference."
      />

      {/* Events Section */}
      <div 
        ref={eventsRef}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
      >
        {loading ? (
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {[1, 2, 3].map((i) => (
              <div key={i} className="animate-pulse">
                <div className="bg-gray-800 rounded-lg overflow-hidden">
                  <div className="aspect-video bg-gray-700" />
                  <div className="p-6 space-y-4">
                    <div className="h-6 bg-gray-700 rounded w-3/4" />
                    <div className="h-4 bg-gray-700 rounded w-full" />
                    <div className="h-4 bg-gray-700 rounded w-full" />
                    <div className="space-y-2">
                      <div className="h-4 bg-gray-700 rounded w-1/2" />
                      <div className="h-4 bg-gray-700 rounded w-2/3" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : events.length === 0 ? (
          <div className="text-center py-12">
            <FiCalendar className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-lg font-medium text-white">No upcoming events</h3>
            <p className="mt-1 text-gray-400">Check back later for new events.</p>
          </div>
        ) : (
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {events.map((event) => (
              <Link
                key={event.id}
                to={`/events/${event.id}`}
                className="bg-gray-800 rounded-lg overflow-hidden hover:bg-gray-700 transition duration-300 group"
              >
                <div className="aspect-video bg-gray-700 relative overflow-hidden">
                  {event.featuredImage?.url ? (
                    <img
                      src={event.featuredImage.url}
                      alt={event.featuredImage.alt || event.title}
                      className="w-full h-full object-cover transform group-hover:scale-105 transition duration-300"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <FiCalendar className="w-12 h-12 text-gray-600" />
                    </div>
                  )}
                </div>

                <div className="p-6">
                  <h3 className="text-xl font-semibold text-white group-hover:text-indigo-400 transition duration-300">
                    {event.title}
                  </h3>
                  <p className="mt-2 text-gray-400 line-clamp-2">
                    {event.description}
                  </p>

                  <div className="mt-4 space-y-2 text-sm text-gray-400">
                    <div className="flex items-center gap-2">
                      <FiCalendar className="w-4 h-4 text-indigo-400" />
                      <span>{formatDate(event.startDate)}</span>
                    </div>
                    {event.location && (
                      <div className="flex items-center gap-2">
                        <FiMapPin className="w-4 h-4 text-indigo-400" />
                        <span>{event.location}</span>
                      </div>
                    )}
                    <div className="flex items-center gap-2">
                      <FiUsers className="w-4 h-4 text-indigo-400" />
                      <span>{event.registeredCount} / {event.capacity} registered</span>
                    </div>
                    <div className="mt-4">
                      <span className={isEventFree(event.price) ? "text-green-400 font-medium" : "text-white font-medium"}>
                        {formatPrice(event.price)}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsPage;
